import { useState, useEffect } from 'react'
import moment from 'moment'
import Loading from 'components/Loading/Loading'

const getEvents = (events, day) => {
    return events.filter(e => {
        const start = moment(e.date)
        if (start.isSame(day, 'day')) return true
        return false
    }).sort((a, b) => {
        return a.start - b.start
    })
}

export default function Calendar({ events, loading = false, addEvent, date, onChangeDate = () => { } }) {
    const [weeks, setWeeks] = useState([])
    const [month, setMonth] = useState(moment().hour(12))
    const [highlighted, setHighlighted] = useState(null)

    useEffect(() => {
        const _year = month.year() + "";
        const _month = (month.month() + 1 + "").padStart(2, "0");
        const _weeks = []
        let week = {}
        _weeks.push(week)
        const day = moment.utc(_year + "/" + _month, "YYYY/MM").startOf("month");
        let weekDay = day.weekday();
        week[weekDay] = {
            entries: getEvents(events, day),
            date: moment.utc(day),
            day: moment.utc(true).format("ddd"),
            dateStr: moment.utc(day).utc(true).format("D"),
        };
        day.add(1, "day");
        while (day.date() > 1) {
            let weekDay = day.weekday();
            if (weekDay === 0) {
                let newWeek = {};
                _weeks.push(newWeek);
                week = newWeek;
            }
            week[weekDay] = {
                entries: getEvents(events, day),
                date: moment.utc(day),
                day: day.utc(true).format("ddd"),
                dateStr: moment.utc(day).utc(true).format("D"),
            };
            day.add(1, "day");
        }
        setWeeks(_weeks)
    }, [month, events])

    useEffect(() => {
        if (month) {
            onChangeDate(month)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (date && (!date.isSame(month, 'month') || !date.isSame(month, 'year'))) {
            setMonth(date)
        }
        // eslint-disable-next-line
    }, [date])

    const moveForward = () => {
        const date = moment(month).add(1, "month");
        setMonth(date)
        onChangeDate(date)
    }

    const moveBack = () => {
        const date = moment(month).subtract(1, "month");
        setMonth(date)
        onChangeDate(date)
    }

    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6]

    return (
        <div className="calendar">
            <div className="flex-container-containerow mar-b20">
                <div className="col-xs-12 center-xs">
                    <div className="flex-container center-xs text-lg">
                        <div className="icon-arrow-left clickable" onClick={moveBack}></div>
                        <div style={{ minWidth: 250 }}>{month.utc(true).format("MMMM")} - {month.utc(true).format('YYYY')}</div>
                        <div className="icon-arrow-right clickable" onClick={moveForward}></div>
                    </div>
                </div>
            </div>
            <div className="flex-container row hide-xs">
                {daysOfWeek.map(day => <div key={day} className="col-md center-xs">{moment().day(day).utc(true).format('ddd')}</div>)}
            </div>
            {weeks.map((week, weekIndex) => <div className="row" key={'week-' + weekIndex}>
                {daysOfWeek.map((day) => {
                    const isToday = week[day] && moment().isSame(week[day].date, "day")
                    const isWeekend = week[day] && (week[day].date.day() === 0 || week[day].date.day() === 6)
                    const first = week[day] && week[day].dateStr === "1" && day
                    let className = 'day'
                    if (week[day]) {
                        if (weekIndex === 0) className += ' noBottom'
                        if (day === 0 || (week[day].dateStr === "1")) className += ' borLeft'
                        if (week[day] && (week[day].dateStr < 8 || weekIndex === 1)) className += ' borTop'
                        if (isToday) className += ' today'
                        if (isWeekend) className += ' weekend'
                        if (first) className += ' first'
                    }

                    return <div key={'week-' + weekIndex + '-day-' + day} className="col-xs-12 col-sm-12 col-md dayContainer">
                        {Boolean(week[day]) && <div className={className}>
                            <div className="text-center">{week[day].dateStr}</div>
                            {Boolean(week[day].entries.length) && <div className="dayInfo">({week[day].entries.length})</div>}
                            {Boolean(loading) && <Loading iconType="2" />}
                            {!Boolean(loading) && <div className="eventContainer">
                                {week[day].entries.map(e => {
                                    return <div
                                        key={'event-' + e.id}
                                        onMouseEnter={() => setHighlighted(e.id)}
                                        onMouseLeave={() => setHighlighted(null)}
                                        className={e.classes + (highlighted === e.id ? ' highlight' : '')}
                                        onClick={e.onClick}
                                    >
                                        <div className={"name " + e.classes}>{e.title}</div>
                                    </div>
                                })}
                            </div>}
                            {!Boolean(loading) && Boolean(addEvent) && <div className="addEvent" onClick={() => addEvent(week[day].date)}>
                                <i className="icon-plus" />
                            </div>}
                        </div>
                        }
                    </div>
                })}
            </div>)
            }
        </div >
    )
}