import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { equipmentReturnMethods, equipmentOwnerships } from "classes/quotesHelpers"
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getRemoveDevicesAdditionalDrawerFormErrors } from 'classes/formErrors/repository';
import { createSelectInput, createTextInput } from 'classes/controls/formControls';


export default function RemoveDevicesAdditionalDrawer({ onFinished, ourDeal, setOurDeal, isActive }) {

    const defaultError = { error: false, message: '' };
    const [saving, setSaving] = useState(false);
    const defaultBanner = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultBanner);
    const defaultFormErrors = { 
      model: defaultError, 
      serial: defaultError, 
      currentLocationAddress: defaultError, 
      equipmentOwnership: defaultError, 
      equipmentOwnerDetails: defaultError,
      returnMethod: defaultError,
      locationOnsite: defaultError  };
    const [formErrors, setFormErrors] = useState(defaultFormErrors);
    const [formData, setFormData] = useState({
      model: "",
      serial: "",
      addressId: "",
      currentLocationAddress: -1,
      locationOnsite: "",
      replacedWith: "",
      equipmentOwnership: -1,
      equipmentOwnerDetails: "",
      returnMethod: -1 });

    const exceptions = [400,404];
  
    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


    const submitForm = async ()  => {
      try {
        setSaving(true);
        const formResult = await checkFormErrors(getRemoveDevicesAdditionalDrawerFormErrors(formData), setFormErrors, null, null, formData, null);
        if (formResult.errorCount === 0) {
          const postResult = await Axios.post(`/entities/removal/${ourDeal.dealID}`, { entity: formData });
          if(exceptions.includes(postResult.status)) {
            if(postResult.status === 400) setBannerError({ error: true, message: invalidConfig });
            if(postResult.status === 404) setBannerError({ error: true, message: notFoundError });
            setSaving(false);
          } else {
            setOurDeal({ ...ourDeal, removal: [...ourDeal.removal, ...postResult.data.result] });  
            onFinished();
            setSaving(false);
          }
        } else {
          setSaving(false);
        }
      } catch(error) {
        console.log(error);
        setBannerError({ error: true, message: exceptionError });
        setSaving(false);
      }
    }

    const changeInput = (event) => {

      let newFormData = {...formData};
      newFormData[event.target.name] = event.target.value;
       
      if(event.target.name === "equipmentOwnership") {
        
        let formErrorsNew = structuredClone(formErrors);
        let isLease = event.target.options[event.target.selectedIndex].text.indexOf("Lease")
        formErrorsNew.equipmentOwnerDetails.disabled = ourDeal.processStage > 0 ? (isLease === -1 || newFormData.equipmentOwnership === '-1') : true;
        setFormErrors(formErrorsNew);
      }
      setFormData(newFormData);
    }


    const selectAddress = (event) => {
      const found = ourDeal.removalStore.find(x => Number(x.AddressId) === Number(event.target.value));
      setFormData({ ...formData, currentLocationAddress: Number(event.target.value), locationOnsite: (found?.Location) ? found.Location : '' });
    }

    return <Fragment>
      <div className='flex-container row'>
        <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Add additional equipment</div>
        <div className='flex-container row start wrap evenly width-100 mar-t2'>
          {createSelectInput('Current Location Address *', 'currentLocationAddress', (!isActive), formData.currentLocationAddress, ourDeal.addressDropdown, (ev) => selectAddress(ev), formErrors, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
          {createSelectInput('Equipment Ownership *', 'equipmentOwnership', (!isActive), formData.equipmentOwnership, equipmentOwnerships, (ev) => changeInput(ev), formErrors, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
          {createSelectInput('Return Method *', 'returnMethod', (!isActive), formData.returnMethod, equipmentReturnMethods, (ev) => changeInput(ev), formErrors, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
          {createTextInput('Model Description *', 'model', (!isActive), formData?.model, (ev) => changeInput(ev), formErrors, 'width-100', null, null, 'width-100')}
          {createTextInput('Serial Number *', 'serial', (!isActive), formData?.serial, (ev) => changeInput(ev), formErrors, 'width-100', null, null, 'width-100')}
          {createTextInput('Location Onsite *', 'locationOnsite', (!isActive), formData?.locationOnsite, (ev) => changeInput(ev), formErrors, 'width-100', null, null, 'width-100')}
          {createTextInput('Equipment Owner Details *', 'equipmentOwnerDetails', (!isActive), formData?.equipmentOwnerDetails, (ev) => changeInput(ev), formErrors, 'width-100', null, null, 'width-100')}
        </div>
        <div className='flex-container row start width-100 mar-t20 mar-r5'>
          <UILIB.Button className='green' disabled={(!ourDeal.isActive || saving)} loading={saving} value='Submit' onClick={async () => await submitForm()} />
          <UILIB.Button className='grey' disabled={saving} value='Cancel' onClick={() => onFinished()} />
          {bannerError.error && <div className="flex-item start wrap width-100">
            <div className="errored message">{bannerError.message}</div>
          </div>}
        </div>
      </div>
    </Fragment>
}