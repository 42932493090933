import { Fragment, useState, useEffect, useRef } from 'react';
import { getBaseData, getDefaultSettings } from './functions';
import Modules from './Modules';


export default function TableNew({ 
  name = "",
  header = [], 
  groupHeader = [],
  defaultSettings = {},
  overflowX = 'auto',
  overflowY = 'auto',
  className = "",  
  outerclassNname = "",  
  optionsContainerclassName = "",
  exportQuery = null,
  exportResponse = null,
  exportHeader = null,
  deleteQuery = null,
  selectQuery = null,
  localQuery = null,
  localRender = [],
  remoteQuery = null,
  remoteRender = []
}) 
{
    const tableData = useRef([]);
    const [loading, setLoading] = useState(false);
    const [render, setRender] = useState(false);
    const [moduleRender, setModuleRender] = useState(false);
    const [checked, setChecked] = useState({});
    const selectAll = useRef(false);
    const settings = useRef(getDefaultSettings(header, name, defaultSettings));  

    useEffect(() => {
      settings.current = getBaseData(header, name, defaultSettings, settings);
    }, [name])

    
    return <Fragment>
      <div className='width-100'>
        <div className={`flex-container row wrap ${outerclassNname}`}>
          <div className={`optionsContainer flex-item width-100 ${optionsContainerclassName}`}>
            {settings.current.paginationEnabled && <Modules.PageSelect settings={settings} render={render} setRender={setRender} setChecked={setChecked} selectAll={selectAll} />}
            {settings.current.filterEnabled && <Modules.ShowTableFilters settings={settings} moduleRender={moduleRender} setModuleRender={setModuleRender} />}
            {settings.current.filterEnabled && <Modules.ClearFilters header={header} settings={settings} defaultSettings={defaultSettings} render={render} setRender={setRender} />}
            {settings.current.expandEnabled && <Modules.ExpandData settings={settings} moduleRender={moduleRender} setModuleRender={setModuleRender} defaultSettings={defaultSettings} />}
            {settings.current.exportEnabled && <Modules.Export header={header} exportHeader={exportHeader} tableData={tableData} name={name} query={remoteQuery} exportQuery={exportQuery} exportResponse={exportResponse} settings={settings} />}
            {settings.current.deleteEnabled && <Modules.Delete query={deleteQuery} checked={checked} setChecked={setChecked} />}
            {settings.current.refreshEnabled && <Modules.Refresh render={render} setRender={setRender} loading={loading} />}
            {settings.current.paginationEnabled && <Modules.RecordSelect tableData={tableData} settings={settings} render={render} setRender={setRender} />}
          </div>
          <div className={`tableContainer width-100`} style={{ overflowX: overflowX, overflowY: overflowY }}>
            <table valign="center" className={`mainTable ${className} width-100`} cellSpacing="0" cellPadding="0">
              {settings.current.headerEnabled && <tr><Modules.ColumnHeader header={header} settings={settings} render={render} setRender={setRender} moduleRender={moduleRender} setModuleRender={setModuleRender} checked={checked} setChecked={setChecked} selectAll={selectAll} tableData={tableData} selectQuery={selectQuery} defaultSettings={defaultSettings} /></tr>}
              {groupHeader && <tr><Modules.ColumnGroup groupHeader={groupHeader} /></tr>}
              {settings.current.filtering && <tr><Modules.ColumnFilters header={header} settings={settings} render={render} setRender={setRender} moduleRender={moduleRender} setModuleRender={setModuleRender} defaultSettings={defaultSettings} /></tr>}
              {settings.current.filtering && <tr><Modules.ColumnInput header={header} settings={settings} render={render} setRender={setRender} moduleRender={moduleRender} query={remoteQuery} /></tr>}
              {<Modules.TableBody header={header} render={render} setRender={setRender} localQuery={localQuery} localRender={localRender} remoteQuery={remoteQuery} remoteRender={remoteRender} moduleRender={moduleRender} setModuleRender={setModuleRender} tableData={tableData} setLoading={setLoading} settings={settings} checked={checked} setChecked={setChecked} selectQuery={selectQuery} />}
            </table>
          </div>
          <div className={`optionsContainer flex-item width-100 ${optionsContainerclassName}`}>
          {settings.current.paginationEnabled && <Modules.PageSelect settings={settings} render={render} setRender={setRender} setChecked={setChecked} selectAll={selectAll} />}
            {settings.current.paginationEnabled && <Modules.RecordSelect tableData={tableData} settings={settings} render={render} setRender={setRender} />}
          </div>
        </div>
      </div>
    </Fragment>
}