import { Fragment, useEffect, useState } from 'react';
import { updateAndSetOurDealType, updateAndSetOurDealNewExisting, updateAndSetOurDealCategory } from 'classes/quotesHelpers';
import { updateDealData, checkAccess } from '../functions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addRow } from './detailPageCommon';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from "moment"

export default function CRMDetails(props) 
  {
    const [refreshLoading, setRefreshLoading] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const [professionalServicesUser, setProfessionalServicesUser] = useState('');
    const exceptions = [400,404];
  
    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while refreshing, please reload or contact support';
    const failureMessage = "Data unavailable";


    function mapPSUsers() {
      const pleaseSelect = { label: "Please Select", value: "-1" };
      if(!props?.ourDeal?.psUsers) return [{ label: "No Users Available", value: "-1" }];
      return [pleaseSelect, ...props.ourDeal.psUsers.map(x => { return { value: x.activeDirectoryObjectGuid, label: x.fullName } })];
    }

    async function refreshCRMData() {
      try {
        setRefreshLoading(true);
        const result = await Axios.patch(`/entities/workflow/deals/actionRefreshData/${props.ourDeal.dealID}/${props.ourDeal.dynamicsOpportunityNumber}`);
        if(exceptions.includes(result.status)) {
          if(result.status === 400) toast.error(invalidConfig);
          if(result.status === 404) toast.error(notFoundError);
        } else {
          props.setOurDeal({ ...props.ourDeal, ...result?.data?.result });
          setRefreshLoading(false);
        }
      }
      catch (error){
        console.log(error);
        toast.error(exceptionError);
        setRefreshLoading(false);
      }
    }

    useEffect(() => {
      const asyncEffect = async () => {
        const permissionResult = await checkAccess(props.account, props.ourDeal);
        setPermissions(permissionResult.users);
        if(props?.ourDeal?.activeDirectoryObjectGuidPreSalesUserId) {
          const userObj = await Axios.get(`/helpers/getProfessionalServicesUser/${props.ourDeal.activeDirectoryObjectGuidPreSalesUserId}`);  
          const user = userObj.data[0] ? userObj.data[0].fullName : null;
          setProfessionalServicesUser(user);
        }
      }
      asyncEffect();
    }, [props.isActive, props.ourDeal.activeDirectoryObjectGuidPreSalesUserId]);

    const preSalesUserIDControl = 
        <UILIB.Select 
            disabled={!props.isActive}
            data={mapPSUsers()} 
            name="activeDirectoryObjectGuidPreSalesUserId"
            value={props.ourDeal.activeDirectoryObjectGuidPreSalesUserId}
            onChange={(ev) => updateDealData({ event: ev, type: props.updateDealDataType, timeout: 500, props: props }) } />;

    const refreshDataControl = <div>
          <UILIB.Button 
              disabled={!props.isActive} 
              loading={refreshLoading}
              style={{ width: "110px", height: "28px", padding: 0 }} 
              value="Refresh" 
              onClick={async () => await refreshCRMData() } />
        </div>;

    const customerLink = (props?.ourDeal?.crm?.primaryAccount?.accountid) 
      ? <UILIB.Link 
          href={`${props?.ourDeal?.links?.dynamics_base_url}/main.aspx?app=d365default&forceUCI=1&pagetype=entityrecord&etn=account&id=${props.ourDeal?.crm?.primaryAccount?.accountid}`} 
          rel="noreferrer" 
          target="_blank">
          <div className="text-12 font-weight-bold">{props?.ourDeal?.crm?.primaryAccount?.name}</div>
        </UILIB.Link>
      : <strong>{(props.ourDeal?.crm?.primaryAccount?.name) ? props.ourDeal.crm.primaryAccount.name : failureMessage}</strong>
    
    const accountLink = (props?.ourDeal?.customerID && props?.ourDeal?.crm?.primaryAccount?.accountnumber) 
      ? <UILIB.Link 
          href={`https://go.vantage.online/#customer/view/${props.ourDeal.customerID}`} 
          rel="noreferrer" 
          target="_blank">
          <div className="text-12 font-weight-bold">{props.ourDeal.crm.primaryAccount.accountnumber}</div>
        </UILIB.Link>
      : <UILIB.Link 
          href={'https://go.vantage.online/#customers'} 
          rel="noreferrer" 
          target="_blank">
          <div className="text-12 font-weight-bold">Create Customer record on VO</div>
        </UILIB.Link>

    const accountLinkFinal = (permissions && !permissions.isSales && !permissions.isSalesAdmin) 
      ? accountLink
      : <strong>{(props.ourDeal?.crm?.primaryAccount?.accountnumber) ? props.ourDeal.crm.primaryAccount.accountnumber : failureMessage}</strong>

    return <Fragment>  
      <UILIB.Paper className="flex-container row height-100">
        <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
          <tbody>
            { addRow('Customer', false, customerLink) }
            { addRow('Account Number', false, accountLinkFinal) }
            { addRow('Customer Grade', false, <strong>{(props?.ourDeal?.crm?.primaryAccount?.dmc_category) ? updateAndSetOurDealCategory(props.ourDeal.crm.primaryAccount.dmc_category) : failureMessage}</strong>) }
            { addRow('Salesperson', false, <strong>{(props?.ourDeal?.crm?.systemusers?.fullname) ? props.ourDeal.crm.systemusers.fullname : failureMessage}</strong>) }
            { addRow('Business Unit', false, <strong>{(props?.ourDeal?.crm?.businessunits?.name) ? props.ourDeal.crm.businessunits.name : failureMessage}</strong>) }
            { addRow('Service Region', false, <strong>{(props?.ourDeal?.crm?.vantageSales?.ServiceRegion) ? props.ourDeal.crm.vantageSales.ServiceRegion : failureMessage}</strong>) }
            { addRow('New/Existing', false, <strong>{(props?.ourDeal?.crm?.opportunities?.new_neworexisting) ? updateAndSetOurDealNewExisting(props.ourDeal.crm.opportunities.new_neworexisting) : failureMessage}</strong>) }
            { addRow('Deal Type', false, <strong>{updateAndSetOurDealType((props?.ourDeal?.crm.opportunities?.dmc_type) ? props.ourDeal.crm.opportunities.dmc_type : failureMessage)}</strong>) }
            { addRow('Opportunity Status', false, <strong>{(props?.ourDeal?.crm?.opportunities?.stepname) ? props.ourDeal.crm.opportunities.stepname : failureMessage}</strong>) }
            { addRow('Booked Date', false, <strong>{(props?.ourDeal?.crm?.opportunities?.new_bookedconfirmation) ? moment(props.ourDeal.crm.opportunities.new_bookedconfirmation).utc(true).format('DD/MM/YYYY') : failureMessage}</strong>) }
            { addRow('PS Quote Required', false, <strong>{(props?.ourDeal?.crm?.opportunities?.new_quotefrompsteamrequired) ? "Yes" : "No"}</strong>) }
            { (props?.ourDeal?.crm?.opportunities?.new_quotefrompsteamrequired) && addRow('PS Team Member', false, preSalesUserIDControl) }
            { addRow('Refresh Data', false, refreshDataControl) }
          </tbody>
        </table>
      </UILIB.Paper>
    </Fragment >
}