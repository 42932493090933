import { useState, useEffect, Fragment } from 'react';
import Axios from "classes/axios";
import UILIB from "components";
import Modules from "../Modules";


export default function UserEventDrawer({ entity }) {

  const [eventMeta, setEventMeta] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    const actionAsync = async () => {
      setLoading(true);
      const workflowEvents = await Axios.get(`/entities/portals_eventTypes`);
      const sourcevents = await Axios.get(`/entities/portals_eventSources`);
      const response = await Axios.get(`/entities/workflow/portals/actionGetUserEvents/${entity.id}`).then(res => res.data);
      const eventsMapped = workflowEvents.data.result.map(x => { return { label: x.name, value: x.id } });    
      const sorucesMapped = sourcevents.data.result.map(x => { return { label: x.name, value: x.id } });
      setEventMeta({ workflow: eventsMapped, source: sorucesMapped });
      setEvents(response?.result);
      setLoading(false);
    };
    actionAsync();
  }, []);


  return <Fragment>
    <div className="flex-container row width-100 wrap mar-t20">
      {(loading || Boolean(events?.length)) && <div className="flex-item start align-center flex-grow-1 font-weight-bold text-16">View Audit Events for {entity.firstName} {entity.lastName}</div>}
      {loading && <UILIB.Loading outerclassNname="flex-container row end center mar-r10" className="-table" type={3} />}
      {!loading && Boolean(!events?.length) && <h2 className="text-16 width-100">No Events available for this User</h2>}
    </div>
    <div className={`flex-container row padding-4`}>
      {Boolean(events?.length) && <div className="width-100">
        {eventMeta.workflow.map((x,i) => {
          const filtered = events.filter(f => f.type === x.label);
          if(!filtered.length) return null
          return <Modules.AuditNoteContainer key={i} type={x.label} data={filtered} /> })}
      </div>}
    </div>
  </Fragment>
}
