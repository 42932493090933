import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import { pleaseSelect, createDateInput, createTextInput, createSelectInput, checkAndSet, newError, emailPattern, postcodePattern, alphaPattern, alphaNumericPattern, mobilePattern, alphaNumericSpaceSpecialPattern, alphaSpacePattern, alphaSpaceSpecialPattern } from '../generic';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';
import DriverCheckCRUD from '../driverCheck/driverCheckCRUD';

export default function DriverCRUD(props) {
    const dispatch = useDispatch();
    const emptyEntity = {
        firstName: '',
        lastName: '',
        jobRole: '',
        peopleHrNumber: '',
        department: '',
        driverType: '',
        licenceNumber: '',
        dateOfFirstFullLicence: '',
        mobileNumber: '',
        workEmail: '',
        addressId: '',
        addressLine1: '',
        addressLine2: '',
        townCity: '',
        countyState: '',
        postcode: '',
        dateOfBirth: '',
        manager: '',
        managersEmail: '',
        region: '',
        office: '',
        roleGrade: '' };
    const entityUrl = `/fleet/driver`;
    const entityUrlWithId = `/fleet/driver/${props.data.entity.id}`;
    const addFromEntityWithKey = {...emptyEntity };
    const drawWidth = '700px';
    const headerText = ' Driver - ';
    const departments = [pleaseSelect,'Development','Finance','Human Resources','Integration & Data','Marketing','Pro Services - Digital Solutions','Pro Services - Helpdesk','Pro Services - IT','Pro Services - MPS','Sales','Sales Admin & Telesales','Senior Management','Service','Service Admin','Telecoms','Warehouse - Logistics - Workshop & Stores'];
    const driverTypes = [pleaseSelect,'Company Car','Car Allowance','Pool Car','Company Van'];
    const regions = [pleaseSelect,'North','Midlands','South','Scotland'];
    const offices = [pleaseSelect,'Southampton','London','Northampton','Croydon','Newcastle','Manchester','Falkirk','Bristol','Leicester','York','Rochester','Glasgow','Inverness','Edinburgh','Aberdeen'];
    const roleGrades = [pleaseSelect,'2C','2B','2A','3C','3B','3A','4B','4A','5B','5A']

    const loadSpecificData = async () => {
    }

    const evaluatedRulesPassed = (formErrorsTemp) => {
        let e = 0;
		e = checkAndSet(!entity.firstName || entity.firstName.length < 1, formErrorsTemp, 'firstName', newError('firstName', 'Please enter First Name of driver'));
		e = e + checkAndSet(!entity.firstName.match(alphaPattern), formErrorsTemp, 'firstName', newError('firstName', 'Please enter First Name of driver without special characters'));

        e = e + checkAndSet(!entity.lastName || entity.lastName.length < 1, formErrorsTemp, 'lastName', newError('lastName', 'Please enter Last Name of driver'));
		e = e + checkAndSet(!entity.lastName.match(alphaPattern), formErrorsTemp, 'lastName', newError('lastName', 'Please enter Last Name of driver without special characters'));

		e = e + checkAndSet(!entity.jobRole.match(alphaSpaceSpecialPattern), formErrorsTemp, 'jobRole', newError('jobRole', 'Please enter Job Role of driver without special characters'));
		e = e + checkAndSet(!entity.peopleHrNumber.match(alphaNumericPattern), formErrorsTemp, 'peopleHrNumber', newError('peopleHrNumber', 'Please enter People HR Number of driver without special characters'));
        e = e + checkAndSet(!entity.driverType || entity.driverType.length < 1 || entity.driverType === pleaseSelect, formErrorsTemp, 'driverType', newError('driverType', 'Please select Driver Type of driver'));
		e = e + checkAndSet(!entity.licenceNumber.match(alphaNumericPattern), formErrorsTemp, 'licenceNumber', newError('licenceNumber', 'Please enter Licence Number of driver without special characters'));
        e = e + checkAndSet(entity.mobileNumber && !entity.mobileNumber.match(mobilePattern), formErrorsTemp, 'mobileNumber', newError('mobileNumber', 'Please enter Mobile Number of driver with only numeric characters'));

        e = e + checkAndSet(!entity.workEmail || entity.workEmail.length < 1, formErrorsTemp, 'workEmail', newError('workEmail', 'Please enter Work Email of driver'));
		e = e + checkAndSet(!entity.workEmail.match(emailPattern), formErrorsTemp, 'workEmail', newError('workEmail', 'Please enter valid Work Email of driver'));

        e = e + checkAndSet(!entity.addressLine1, formErrorsTemp, 'addressLine1', newError('addressLine1', 'Please enter Address Line 1 of driver'));
		e = e + checkAndSet(!entity.addressLine1.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'addressLine1', newError('addressLine1', 'Please enter Address Line 1 of driver without special characters'));

        e = e + checkAndSet(!entity.addressLine2.match(alphaSpacePattern), formErrorsTemp, 'addressLine2', newError('addressLine2', 'Please enter Address Line 2 of driver without special characters'));
		e = e + checkAndSet(!entity.townCity.match(alphaSpacePattern), formErrorsTemp, 'townCity', newError('townCity', 'Please enter Town City of driver without special characters'));
		e = e + checkAndSet(!entity.countyState.match(alphaSpacePattern), formErrorsTemp, 'countyState', newError('countyState', 'Please enter County of driver without special characters'));

        e = e + checkAndSet(!entity.postcode, formErrorsTemp, 'postcode', newError('postcode', 'Please enter Postcode of driver'));
		e = e + checkAndSet(!entity.postcode.match(postcodePattern), formErrorsTemp, 'postcode', newError('postcode', 'Please enter Postcode of driver without special characters'));

        if (entity.dateOfBirth.length > 0 && entity.dateOfBirth !== undefined && entity.dateOfBirth !== 'Invalid date') {
		    e = e + checkAndSet(entity.dateOfBirth > moment().subtract(16, 'years').utc(true).format('YYYY-MM-DD'), formErrorsTemp, 'dateOfBirth', newError('dateOfBirth', 'Driver must be over 16 yeard old'));
        }

        e = e + checkAndSet(!entity.manager || entity.manager.length < 1, formErrorsTemp, 'manager', newError('manager', 'Please enter Manager of driver'));
		e = e + checkAndSet(!entity.manager.match(alphaSpacePattern), formErrorsTemp, 'manager', newError('manager', 'Please enter Manager of driver without special characters'));

        e = e + checkAndSet(!entity.managersEmail || entity.managersEmail.length < 1, formErrorsTemp, 'managersEmail', newError('managersEmail', 'Please enter Managers Email of driver'));
		e = e + checkAndSet(!entity.managersEmail.match(emailPattern), formErrorsTemp, 'managersEmail', newError('managersEmail', 'Please enter valid Managers Email of driver'));

        e = e + checkAndSet(!entity.region || entity.region.length < 1 || entity.region === pleaseSelect, formErrorsTemp, 'region', newError('region', 'Please select Region of driver'));
        e = e + checkAndSet(!entity.office || entity.office.length < 1 || entity.office === pleaseSelect, formErrorsTemp, 'office', newError('office', 'Please select Office of driver'));

        return e > 0;
	}

    const htmlButtons = (loading) => {
        if (loading) {
            return <div />
        } else {
            return <div>
                <UILIB.Button loading={saving} className='mar-t10' value='Action' onClick={submitForm} />
                {(props.data.type === editType) && <UILIB.Button className='mar-t10 mar-l10' value='Add Driver Check' onClick={addDriverCheck} />}
            </div>
        }
    }
    
    const htmlRows = (loading) => { 
        if (loading) {
            return <div />
        } else {
            return <div className='mar-t15'>
                {createTextInput('First Name', 'firstName', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Last Name', 'lastName', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Job Role', 'jobRole', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('People HR Number', 'peopleHrNumber', isActive, 30, entity, entityErrors, changeInput)}
                {createSelectInput('Department', 'department', departments, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Driver Type', 'driverType', driverTypes, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Licence Number', 'licenceNumber', isActive, 40, entity, entityErrors, changeInput)}
                {createDateInput('Date Of First Full Licence', 'dateOfFirstFullLicence', isActive, entity, entityErrors, changeInput)}
                {createTextInput('Mobile Number', 'mobileNumber', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Work Email', 'workEmail', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Address Line 1', 'addressLine1', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Address Line 2', 'addressLine2', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Town or City', 'townCity', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('County', 'countyState', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Postcode', 'postcode', isActive, 20, entity, entityErrors, changeInput)}
                {createTextInput('Manager', 'manager', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Managers Email', 'managersEmail', isActive, 40, entity, entityErrors, changeInput)}
                {createDateInput('Date Of Birth', 'dateOfBirth', isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Region', 'region', regions, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Office', 'office', offices, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Role Grade', 'roleGrade', roleGrades, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Created At', 'createdAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Created By', 'createdBy', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated At', 'updatedAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated By', 'updatedBy', false, 20, entity, entityErrors, changeInput)}
            </div>
        }
    }

    const addDriverCheck = (data) => {
        dispatch(setDrawer({ open: true, content: <DriverCheckCRUD data={{ type: 'addFrom', entity: { workEmail: entity['workEmail'] }}} sync={syncEditSave} />, width: drawWidth }));
    };

    const syncEditSave = (data) => {
    }

    //Generic
    const addType = 'add';
    const addFromType = 'addFrom';
    const editType = 'edit';
    const deleteType = 'delete';
    const defaultPageMessage = 'Please complete the below and then click Action.';
    const deleteConfirmationMessage = 'Please confirm deletion of the below details by clicking Action.';
    const failValidationMessage = 'Please correct any errors and click Action again.';
    const rowVersionMessage = 'A newer version of this record has already been saved. Please reload the data.';
    const missingRecordMessage = 'Unable to find record in db. Please reload the data.';
    const invalidSubmitMessage = 'Invalid submit request, please advise support.';
    const saveErrorMessage = 'Unable to save, please advise support.';
    const [loadingData, setLoadingData] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isActiveOnEdit, setIsActiveOnEdit] = useState(true);
    const [entity, setEntity] = useState(emptyEntity);
    const [entityErrors, setEntityErrors] = useState(emptyEntity);
    const [errored, setErrored] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(defaultPageMessage);

    useEffect(() => {
        const loadEntity = async () => {
            if (props.data.type === addType) {
                setEntity(emptyEntity);
            } else if (props.data.type === addFromType) {
                const entity = addFromEntityWithKey;
                setEntity(entity);
            } else {        
                const entityData = await Axios.get(entityUrlWithId);
                setEntity(entityData.data.result);
            }

            setLoadingData(false);
        }

        if (props.data.type === deleteType) {
            setIsActive(false);
            setIsActiveOnEdit(false);
            setMessage(deleteConfirmationMessage)
        }

        if (props.data.type === editType || props.data.type === addFromType) {
            setIsActiveOnEdit(false);
        }

        loadSpecificData();
        loadEntity();
    }, [])

    const changeInput = (event) => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        newFormData[event.target.name] = event.target.value;
        setEntity(newFormData);

        if (event.target.name in entityErrors) {
			let newFormErrors = { ...entityErrors };
			newFormErrors[event.target.name] = '';
			setEntityErrors(newFormErrors);
		}
    }

    const validSubmit = () => {
        let formErrorsTemp = emptyEntity;        
        if (!evaluatedRulesPassed(formErrorsTemp)) {
            return true;
        }
        
        setEntityErrors(formErrorsTemp);
        setMessage(failValidationMessage);
        return false;
    }

    const processDelete = async () => {
        if (props.data.type === deleteType) {
            setSaving(true);
            await Axios.delete(entityUrlWithId, { data: { entity: entity } });
            setDeleted(true);
            props.sync({ deleted: true, id: props.data.entity.id });
            setSaving(false);

            return true;
        }

        return false;
    }

    const processAdd = async () => {
        if (props.data.type === addType || props.data.type === addFromType) {
            setSaving(true);
            const entityResult = await Axios.post(entityUrl, entity);
            props.data.type = editType;
            props.data.entity.id = entityResult.data.result.id;
            setEntity(entityResult.data.result);
            props.sync(entityResult.data.result);
            setIsActiveOnEdit(false);
            setSaving(false);

            return true;
        }
        
        return false;
    }

    const processEdit = async () => {
        if (props.data.type === editType) {
            setSaving(true);
            const entityResult = await Axios.put(entityUrlWithId, entity);

            if (entityResult.status === 200) {
                setEntity(entityResult.data.result);
                props.sync(entityResult.data.result);
            }

            if (entityResult.status === 409) {
                setMessage(rowVersionMessage);
                setErrored(true);
            }

            if (entityResult.status === 400) {
                setMessage(missingRecordMessage);
                setErrored(true);
            }
            
            setSaving(false);
            return true;		
        }

        return false;
    }

    const submitForm = async () => {
        try {
            if (await processDelete()) { return; }
            if (validSubmit()) {
                if (await processAdd()) { return; }
                if (await processEdit()) { return; }

                setMessage(invalidSubmitMessage);
                setErrored(true);
            }
        }
        catch (err) {
            console.log(err);
            setMessage(saveErrorMessage);
            setErrored(true);
        }
    }

    if (deleted) return <UILIB.Paper className='width-100'><p className="center">Deleted</p></UILIB.Paper>

    if (errored) return <UILIB.Paper className='width-100'><p className="center">{message}</p></UILIB.Paper>

    if (loadingData) return <UILIB.Loading type={3} />

    return (
        <div className="mar-l15 mar-r15">
            <h2 className='mar-b10'>{(props.data.type === addType || props.data.type === addFromType) ? 'Add' : 'Edit'}{headerText}{entity.id}</h2>
            <p>{message}</p>
            {htmlButtons(loadingData)}
            {htmlRows(loadingData)}
        </div> 
    )
}