import { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom'
import { exportData, toastContainer } from '../../shared/functions';
import { useDispatch, useSelector } from 'react-redux';
import { setFleetHubReportSelected, setDrawer } from 'store/actions';
import { formatDateTime, formatCurrency } from 'classes/format';
import moment from 'moment';
import UILIB from 'components';
import Axios from 'classes/axios'
import FleetMenu from '../../shared/fleetMenu';
import DrawCRUD from './OwnershipCRUD';
import DocumentsModal from '../documents/modal/documentsModal';

export default function OwnershipTable(props) {
  const history = useHistory();

  //exports
  const [exporting, setExporting] = useState({ 
    name: 'Export All', 
    exportUrl: '/fleet/ownerships/export', 
    fileName: `Ownership_Export_${moment().utc(true).format('YYYYMMDD-HHMMss')}`, 
    setExporting: false 
  });

  //documents
  const [showDocumentsModal, setShowDocumentsModal] = useState({ show: false, entity: null });

  //Specific to entity
  const entityName = 'Ownership';
  const entityUrl = `/fleetHub/ownerships/all`;
  const entityApiUrl = '/fleet/ownerships/all';
  const entityUrlPoolCars = '/fleetHub/ownerships/poolCars';
  const entityApiUrlPoolCars = '/fleet/ownerships/poolCars';
  const entityUrlTotalCars = '/fleetHub/ownerships/totalCars';
  const entityApiUrlTotalCars = '/fleet/ownerships/totalCars';
  const entityAddText = 'Add Ownership';
  const drawWidth = '700px';
  const headerData = [
    { label: 'Id', value: 'id', type: 'number' },
    { label: "First Name", value: "firstName", type: 'string' },
    { label: "Last Name", value: "lastName", type: 'string' },
    { label: "People HR Number", value: "peopleHrNumber", type: 'string' },
    { label: "Work Email", value: "workEmail", type: 'string' },
    { label: "Registration", value: "registration", type: 'string' },
    { label: "P11D", value: "p11d", type: 'string' },
    { label: "Associate From", value: "associationFrom", type: 'string' },
    { label: "From Hour", value: "associationFromTime", type: 'string' },
    { label: "Associate To", value: "associationTo", type: 'string' },
    { label: "To Hour", value: "associationToTime", type: 'string' },
    { label: "Associate Type", value: "associationType", type: 'string' },
    { label: "Contract Type", value: "contractType", type: 'string' },
    { label: 'Documents', value: 'documents', type: 'number', width: '110px' },
    { label: '', value: 'delete' } ];
  const [entityUrlCurrent, setEntityUrlCurrent] = useState(props.pick);

  async function getTableData(entityData) {
    let tableData = [];
    if (entityData && entityData.length) {
      tableData = await Promise.all(entityData.map(async entity => {
        const link = <UILIB.Button value={entity.id} onClick={() => drawCRUD({ type: 'edit', entity: entity })} className='secondary table text-small width-100' />
        const documentCount = await Axios.get(`/fleet/documents/${entityName}/count?documentId=${entity.documentIdentity}`);
        return {
          id: { value: link, raw: entity.id },
          firstName: { value: entity.firstName, raw: entity.firstName },
          lastName: { value: entity.lastName, raw: entity.lastName },
          peopleHrNumber: { value: entity.peopleHrNumber, raw: entity.peopleHrNumber },
          workEmail: { value: entity.workEmail, raw: entity.workEmail },
          registration: { value: entity.registration, raw: entity.registration },
          p11d: { value: formatCurrency(entity.p11d), raw: entity.p11d },
          associationFrom: { value: formatDateTime(entity.associationFrom, 'YYYY-MM-DD'), raw: entity.associationFrom },
          associationFromTime: { value: entity.associationFromTime, raw: entity.associationFromTime },
          associationTo: { value: formatDateTime(entity.associationTo, 'YYYY-MM-DD'), raw: entity.associationTo },
          associationToTime: { value: entity.associationToTime, raw: entity.associationToTime },
          associationType: { value: entity.associationType, raw: entity.associationType },
          contractType: { value: entity.contractType, raw: entity.contractType },
          documents: { value: <UILIB.Button className='secondary table text-small width-100' value={`View (${documentCount.data.result})`} onClick={() => setShowDocumentsModal({ show: true, entity: entity })} />, raw: documentCount.data.result },
          delete: { value: <div onClick={() => drawCRUD({ type: 'delete', entity: entity })} className={'icon-trash2 pointer center text-primary mar-t5'} /> }
          }
      }));
    }

    setTableData(tableData);
  }

  //Generic from here on down
  const dispatch = useDispatch();
  const reduxSelected = useSelector((state) => state.fleetReportSelected);
  const [tableData, setTableData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const tableDataRaw = useRef([]);

  const css = (item) => {
    const entityUrlCurrentNew = (history.location.stats ? history.location.pathname.replace('fleetHub', 'fleet') : entityUrlCurrent);
    return (entityUrlCurrentNew === item) ? 'mar-b10 mar-r10 text-bold' : 'mar-b10 mar-r10';
  }

  const drawCRUD = (data) => {
    dispatch(setDrawer({ open: true, content: <DrawCRUD data={data} sync={syncEntity} />, width: drawWidth }));
  }

  const syncEntity = async (entity) => {
    let result = {};
    if ('deleted' in entity) {
      result = tableDataRaw.current;
      const index = result.map(x => x.id).indexOf(entity.id);
      result.splice(index, 1);
    } else if (!tableDataRaw.current.find(x => x.id === entity.id)) {
      result = tableDataRaw.current;
      result.push(entity);
    } else {
      result = tableDataRaw.current.map((item) => { return item.id === entity.id ? entity : item });
    }

    tableDataRaw.current = result;
    await getTableData(result);
  }

  useEffect(() => {    
    const actionAsync = async () => { 
      setLoadingData(true);
      await props.permissions.generalAccess();
      dispatch(setFleetHubReportSelected({ ...reduxSelected, selected: history.location.pathname.replace('fleetHub', 'fleet') }));
      const entities = await Axios.get(history.location.pathname.replace('fleetHub', 'fleet'));
      tableDataRaw.current = entities.data.result;
      await getTableData(entities.data.result);
      setLoadingData(false);
    }

    actionAsync();
  }, [entityUrlCurrent, history.location])

  return (
    <Fragment>
      <FleetMenu selectedButton={entityName} />
      {toastContainer(1000, 300)}
      <UILIB.Paper className='mar-l10 mar-r10'>
        <UILIB.Button className='mar-b10 mar-r15' value={entityAddText} onClick={() => drawCRUD({ type: 'add', entity: {}})} />
        <UILIB.Button className={css(entityApiUrl)} value='All' onClick={() => {
          setEntityUrlCurrent(entityApiUrl);
          history.push(entityUrl);
        }} />
        <UILIB.Button className={css(entityApiUrlPoolCars)} value='Pool Cars Available' onClick={() => {
          setEntityUrlCurrent(entityApiUrlPoolCars);
          history.push(entityUrlPoolCars);
        }} />
        <UILIB.Button className={css(entityApiUrlTotalCars)} value='Total Cars Available' onClick={() => {
          setEntityUrlCurrent(entityApiUrlTotalCars);
          history.push(entityUrlTotalCars);
        }} />
        <UILIB.Button className='mar-l10 mar-b10 secondary' disabled={!tableDataRaw.current.length} value={exporting.name} onClick={async () => await exportData(exporting, setExporting)} />
        <UILIB.Table
          height='100%'
          overflowX='auto'
          overflowY='hidden'
          loading={loadingData}
          header={headerData}
          data={tableData}
          sortable={true}
          filter={true}
          resetFilters={false}
          goExport={false}
          maxRows={40}
          autoResize={false}
          exportEnable={true} />
       {showDocumentsModal.show && (
        <DocumentsModal 
          showDocumentsModal={showDocumentsModal}
          setShowDocumentsModal={setShowDocumentsModal}
          entity={showDocumentsModal.entity}
          entityTitle={showDocumentsModal.entity.firstName + ' ' + showDocumentsModal.entity.lastName}
          entityName={entityName}
        />)}
      </UILIB.Paper>
    </Fragment> 
  );
}