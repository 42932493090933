import CustomerLookup from './modules/customerLookup';
import GuardianReport from './modules/guardianReport';
import RequestPortalDrawer from './modules/requestPortalDrawer';
import RequestsLookup from './modules/requestsLookup';
import EditRequestDrawer from './modules/requestDrawer';
import RegistrationLookup from './modules/registrationLookup';
import UserLookup from './modules/userLookup';
import ReportDrawer from './modules/reportDrawer';
import EditUserDrawer from './modules/usersDrawer';
import CustomerDrawerEvent from './modules/customerDrawerEvent';
import ViewNotesDrawer from './modules/viewNotesDrawer';
import PortalUtilities from './modules/portalUtilities';
import UserMigration from './modules/userMigration';
import ViewAuditDrawer from './modules/viewAuditDrawer';
import EditCustomerDrawer from './modules/customerDrawer';
import CurrentOwner from './modules/customerDrawer/currentOwner';
import SelectMode from './modules/customerDrawer/selectMode';
import SelectUser from './modules/customerDrawer/selectUser';
import SelectAction from './modules/customerDrawer/selectAction';
import ConfirmAction from './modules/customerDrawer/confirmAction';
import AddNewOwner from './modules/customerDrawer/addNewOwner';
import AuditDrawer from './modules/auditDrawer';
import AuditNoteContainer from './modules/auditNoteContainer';


const exported = {
  CustomerLookup,
  GuardianReport,
  RequestPortalDrawer,
  RequestsLookup,
  RegistrationLookup,
  EditRequestDrawer,
  UserLookup,
  ReportDrawer,
  EditUserDrawer,
  CustomerDrawerEvent,
  ViewNotesDrawer,
  PortalUtilities,
  UserMigration,
  ViewAuditDrawer,
  EditCustomerDrawer,
  CurrentOwner,
  SelectMode,
  SelectUser,
  SelectAction,
  ConfirmAction,
  AddNewOwner,
  AuditDrawer,
  AuditNoteContainer
}
export default exported;