import { useState, useEffect, Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import Axios from 'classes/axios';
import UILIB from 'components';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, registerables as registerablesJS } from "chart.js";
import { Chart } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ...registerablesJS);

export default function ConsumablesLookUpInkPercentages({ serialNumber, months }) {
  const [loadingData, setLoadingData] = useState(true);
  const [show, setShow] = useState(true);
  const [labels, setLabels] = useState([]);
  const [blackData, setBlackData] = useState([]);
  const [cyanData, setCyanData] = useState([]);
  const [magentaData, setMagentaData] = useState([]);
  const [yellowData, setYellowData] = useState([]);
  const [missingData, setMissingData] = useState([]);

  useEffect(() => {    
    const actionAsync = async () => {
      setLoadingData(true);

      const black = await Axios.get(`/entities/workflow/actionInkPercentage/${serialNumber}/${months}/Black`).then(api => api.data.result);
      setLabels(black.map(x => formatDateTime(x.statsDate, 'DD/MM/YY')));
      setBlackData(black.map(x => x.st_BlackPercentage));
      setMissingData(black.map(x => x.missing))

      const magenta = await Axios.get(`/entities/workflow/actionInkPercentage/${serialNumber}/${months}/Magenta`).then(api => api.data.result);
      setMagentaData(magenta.map(x => x.st_MagentaPercentage));

      const cyan = await Axios.get(`/entities/workflow/actionInkPercentage/${serialNumber}/${months}/Cyan`).then(api => api.data.result);
      setCyanData(cyan.map(x => x.st_CyanPercentage));

      const yellow = await Axios.get(`/entities/workflow/actionInkPercentage/${serialNumber}/${months}/Yellow`).then(api => api.data.result);
      setYellowData(yellow.map(x => x.st_YellowPercentage));

      setLoadingData(false);
    }
  
    if(show) {
      actionAsync();
    }
  }, [serialNumber, months, show])

  const data = {
    labels: labels,
    datasets: [
      { type: 'line', label: 'Black', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderColor: 'rgba(0, 0, 0, 0.5)', pointRadius: 1, data: blackData },
      { type: 'line', label: 'Cyan', backgroundColor: 'rgba(0,255,255, 0.5)', borderColor: 'rgba(0,255,255, 0.5)', pointRadius: 1, data: cyanData },
      { type: 'line', label: 'Magenta', backgroundColor: 'rgba(255,0,255, 0.5)', borderColor: 'rgba(255,0,255, 0.5)', pointRadius: 1, data: magentaData },
      { type: 'line', label: 'Yellow', backgroundColor: 'rgba(255, 255, 99, 0.7)', borderColor: 'rgba(255, 255, 99, 0.7)', pointRadius: 1, data: yellowData },
      { type: 'bar', categoryPercentage: 1.0, barPercentage: 1.0, label: 'Missing', backgroundColor: 'rgb(255,0,0, 0.2)', borderColor: 'rgb(255,0,0, 0.2)', data: missingData}
    ]
  }
 
  const config = {
    options: {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        },
        scales: {
            y: {
                min: 0,
                max: 100,
                ticks: {
                    stepSize: 10,
                }
            }
        }
    }
  };
          
  return <Fragment>
    <UILIB.Paper className='width-100'>
      <div className='flex-container wrap mar-b10 '>
        <div className='flex-item align-center flex-grow-1 start wrap'><h3 className='mar-b0'>Ink Percentages - Last {months} Months</h3></div>
        <div className='flex-item end wrap'><UILIB.Button name='showHide' value='Show/Hide' onClick={() => {setShow(!show)}} /></div>
      </div>
      {loadingData && show && <p>Loading...</p>}
      {!loadingData && show && <Chart data={data} options={config.options} height='45px' />}
    </UILIB.Paper>
  </Fragment>
}