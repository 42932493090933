import { useRef, forwardRef, Fragment } from 'react';
import { useReactToPrint } from "react-to-print";
import { Bar, Pie } from "react-chartjs-2";
import moment from "moment/moment";
import { getBarGraphOptions } from '../functions';
import { findLongestArray, SectionHeader } from '../functions';
import '../../../../tailwind.css';




export const StatisticsReport = forwardRef(({ currentUser, data, dateFilters }, ref) => {

  const printRef = useRef();
  const handlePrint = useReactToPrint({ content: () => printRef.current });
  ref.current = { handlePrint };

  const getVolumesByQuarter = (data) => {
    const groupedData = Object.values(
      data.reduce((acc, item) => {
        const date = moment(
          `${item.year}-${item.month.toString().padStart(2, "0")}-01`
        );

        const quarter = date.quarter();

        if (!acc[`${quarter}-${item.year}`]) {
          acc[`${quarter}-${item.year}`] = {
            label: `QTR${quarter}`,
            year: item.year,
            quarter: quarter,
            mono: 0,
            colour: 0,
          };
        }

        acc[`${quarter}-${item.year}`].mono += item.mono;
        acc[`${quarter}-${item.year}`].colour += item.colour;

        return acc;
      }, {})
    );

    groupedData.sort((a, b) => {
      if (a.year < b.year) {
        return -1;
      } else if (a.year > b.year) {
        return 1;
      } else {
        // If years are equal, compare by quarter
        return a.quarter - b.quarter;
      }
    });

    return groupedData;
  };

  const getVolumeBreakdown = () => {
    let proccessed = [];

    //Get unique serial numbers
    let uniqueSerialNo = Array.from(
      new Set([...data.reportVolumes.map((v) => v.serialNumber)])
    );

    uniqueSerialNo.forEach((serial) => {
      //Get all records for that serial
      let recordsForSn = data.reportVolumes.filter(
        (v) => v.serialNumber === serial
      );

      //group by quarter and get totals
      let grouped = getVolumesByQuarter(recordsForSn);

      proccessed.push({
        serialNumber: recordsForSn[0].serialNumber,
        description: recordsForSn[0].description,
        data: grouped,
        totalMono: grouped
          .map((x) => x.mono)
          .reduce((sum, val) => sum + val, 0),
        totalColour: grouped
          .map((x) => x.colour)
          .reduce((sum, val) => sum + val, 0),
      });
    });

    return proccessed;
  };

  const getVolumeTotals = () => {
    let monoTotal = 0;
    let colourTotal = 0;
    data.volumeSummary.forEach((summary) => {
      monoTotal += summary.mono;
      colourTotal += summary.colour;
    });

    let total = monoTotal + colourTotal;
    let colourPercent = (colourTotal / total) * 100;
    let monoPercent = (monoTotal / total) * 100;

    return {
      mono: monoTotal,
      colour: colourTotal,
      total,
      monoPercent,
      colourPercent,
    };
  };

  const getMonoColourVolumeTotals = () => {
    const { mono, colour } = getVolumeTotals();

    return {
      labels: ["Mono", "Colour"],
      datasets: [
        {
          label: `Prints`,
          data: [mono, colour],
          borderColor: ["rgb(9, 0, 65)", "rgb(44, 222, 84)"],
          backgroundColor: ["rgb(9, 0, 65)", "rgb(44, 222, 84)"],
        },
      ],
    };
  };

  const getMonoColourVolumesByQuarter = () => {
    const groupedData = getVolumesByQuarter(data.volumeSummary);

    return {
      labels: [...groupedData.map((el) => `${el.year} - QTR${el.quarter}`)],
      datasets: [
        {
          label: `Mono`,
          data: [...groupedData.map((el) => el.mono)],
          borderColor: "rgb(9, 0, 65)",
          backgroundColor: "rgb(9, 0, 65)",
        },
        {
          label: `Colour`,
          data: [...groupedData.map((el) => el.colour)],
          borderColor: "rgb(44, 222, 84)",
          backgroundColor: "rgb(44, 222, 84)",
        },
      ],
    };
  };

  const getFixTimeData = () => {
    const within1Hours = data.reportFixTimes.reduce(
      (acc, val) => (acc += val.within1Hour),0);
    const within2Hours = data.reportFixTimes.reduce(
      (acc, val) => (acc += val.within2Hours),0);
    const within3Hours = data.reportFixTimes.reduce(
      (acc, val) => (acc += val.within3Hours),0);
    const within4Hours = data.reportFixTimes.reduce(
      (acc, val) => (acc += val.within4Hours),0);
    const over4Hours = data.reportFixTimes.reduce(
      (acc, val) => (acc += val.over4Hours),0);

    return {
      labels: [
        "Within 1 Hour",
        "Within 2 Hours",
        "Within 3 Hours",
        "Within 4 Hours",
        "Over 4 Hours",
      ],
      datasets: [
        {
          label: `Fix Time`,
          data: [
            within1Hours,
            within2Hours,
            within3Hours,
            within4Hours,
            over4Hours,
          ],
          borderColor: [
            "rgb(9, 0, 65)",
            "rgb(44, 222, 84)",
            "rgb(96, 126, 143)",
            "rgb(81, 245, 144)",
            "rgb(151, 175, 189)",
          ],
          backgroundColor: [
            "rgb(9, 0, 65)",
            "rgb(44, 222, 84)",
            "rgb(96, 126, 143)",
            "rgb(81, 245, 144)",
            "rgb(151, 175, 189)",
          ],
        },
      ],
    };
  };

  const volumeBreakdown = getVolumeBreakdown();
  const longest = findLongestArray(volumeBreakdown, "data");
  const numPrevYearQrts = longest.data.filter((d) => d.year === longest.data[0].year).length;
  const numCurrYearQrts = longest.data.length - numPrevYearQrts;


  return (
    <>
      <style type="text/css" media="print">
        {
          "table { page-break-after:auto } tr { page-break-inside:avoid; page-break-after:auto } td { page-break-inside:avoid; page-break-after:auto } thead { display:table-header-group } tfoot { display:table-footer-group } .pagebreak { page-break-before: always; } @page { size: A4 landscape; margin: 24px; }"
        }
      </style>
      <div style={{ height: 0, width: '1800px', overflow: "hidden" }}>
        <div ref={printRef} className="tw-bg-white">
          <div id="print-content">
            {/* FRONT PAGE */}
            <div className="tw-mx-auto tw-w-[44rem] tw-text-center">
              <img
                src={`data:image/png;base64,${decodeURIComponent(data.agilicoLogoLarge)}`}
                style={{ width: 542, height: 200 }}
                alt="Agilico Logo"
                className="tw-mx-auto tw-mt-48"
              />
              <h1 className="tw-text-ag-default tw-text-4xl tw-mt-10">
                Customer Review Report
              </h1>
              <h4 className="tw-text-ag-default tw-text-xl tw-mt-10">
                Period: {moment(dateFilters.startDate).utc(true).format("Do MMMM YYYY")}{" "}
                - {moment(dateFilters.endDate).utc(true).format("Do MMMM YYYY")}{" "}
              </h4>
            </div>
            {/* END FRONT PAGE */}

            <div className="portalhub pagebreak">
              <SectionHeader
                customerName={currentUser.customer[0].Name}
                section="Device Register"
                smallLogoBase64={data.agilicoLogoSmall}
              />
              <div className="tw-my-6">
                <p>
                  The device register report enables you to track all your
                  printing assets at a glance. Use it to see the latest meter
                  reading totals and in which week of the month your
                  Preventative Maintenance visits are scheduled. This report
                  is also key to planning any future requirements as it
                  contains your machines' locations and when their associated
                  maintenance agreements are due to expire. It could be a good
                  time to discuss the latest technology with your account
                  manager.
                </p>
              </div>
              <table className="tw-w-full tw-divide-y tw-border tw-border-solid tw-divide-gray-300 portalhub table">
                <thead>
                  <tr className="tw-border-b-2 tw-border-gray-300 portalhub">
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Postcode
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Device Type
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Model Type
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Serial No
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Location
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Install Date
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Mono
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Colour
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.reportDevices.map((row, i) => (
                    <tr
                      className={`${
                        i % 2 === 0 ? "tw-bg-gray-100" : "tw-bg-white"
                      }`}
                    >
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.postCode}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.deviceType}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.modelType}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.serialNo}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.locatedAt}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.installDate}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.mono}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.colour}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="portalhub pagebreak">
              <SectionHeader
                customerName={currentUser.customer[0].Name}
                section="Print Volume Overview"
                smallLogoBase64={data.agilicoLogoSmall}
              />
              <div className="tw-my-6">
                <p>
                  This report helps you to easily see your total print and
                  copy volumes across your billing periods This is handy to
                  keep an eye on your organisation's overall printing trends
                  and ensure that your volumes are tracking in line with your
                  anticipated budget. Looking at the relationship between
                  colour and mono over time can reveal important information
                  too. If colour printing is increasing it may be worthwhile
                  performing some user training.
                </p>
              </div>
              <div className="tw-grid tw-grid-cols-11">
                <div className="tw-col-span-5">
                  <Bar
                    options={{ ...getBarGraphOptions(), responsive: true }}
                    data={getMonoColourVolumesByQuarter()}
                  />
                </div>
                <div className="tw-col-span-3" />
                <div className="tw-col-span-2 tw-mr-8">
                  <Pie
                    options={{
                      responsive: true,
                    }}
                    data={getMonoColourVolumeTotals()}
                  />
                  <table className="tw-mt-6 tw-ml-4 tw-w-full tw-text-sm tw-text-gray-600 tw-text-center tw-border portalhub table">
                    <tr>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        <div className="tw-flex tw-items-center">
                          <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-ag-default" /> Mono
                        </div>
                      </td>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        {Number(getVolumeTotals().mono).toLocaleString()}
                      </td>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        {Number(getVolumeTotals().monoPercent).toFixed(2)}%
                      </td>
                    </tr>
                    <tr>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        <div className="tw-flex tw-items-center">
                          <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-ag-lightGreen" />
                          Colour
                        </div>
                      </td>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        {Number(getVolumeTotals().colour).toLocaleString()}
                      </td>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        {Number(getVolumeTotals().colourPercent).toFixed(2)}%
                      </td>
                    </tr>
                    <tr>
                      <td className="full-border"/>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        {Number(getVolumeTotals().total).toLocaleString()}
                      </td>
                      <td className="full-border"/>
                    </tr>
                  </table>
                </div>
                <div className="tw-col-span-8">
                  <table className="tw-mt-6 tw-mr-4 tw-w-full tw-text-sm tw-text-gray-600 tw-text-center tw-border portalhub table">
                    <tr>
                      <td className="full-border"></td>
                      {getVolumesByQuarter(data.volumeSummary).map(
                        (el) => (
                          <td className="tw-border full-border">
                            {`${el.year} - QTR${el.quarter}`}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        <div className="tw-flex tw-items-center">
                          <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-ag-default" /> Mono
                        </div>
                      </td>
                      {getVolumesByQuarter(data.volumeSummary).map(
                        (el) => (
                          <td className="tw-border tw-px-3 tw-py-1 full-border">
                            {Number(el.mono).toLocaleString()}
                          </td>
                        )
                      )}
                    </tr>
                    <tr>
                      <td className="tw-border tw-px-3 tw-py-1 full-border">
                        <div className="tw-flex tw-items-center">
                          <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-ag-lightGreen" />{" "}
                          Colour
                        </div>
                      </td>
                      {getVolumesByQuarter(data.volumeSummary).map(
                        (el) => (
                          <td className="tw-border tw-px-3 py-1 full-border">
                            {Number(el.colour).toLocaleString()}
                          </td>
                        )
                      )}
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div className="portalhub pagebreak">
              <SectionHeader
                customerName={currentUser.customer[0].Name}
                section="Print Volume Detailed"
                smallLogoBase64={data.agilicoLogoSmall}
              />
              <div className="tw-my-6">
                This report gives you a full breakdown of your mono and colour
                printing volumes for your review period by location and
                device. How does it look? If a particular department's volumes
                are running high then it could be a good time to introduce
                low-cost print management software. If a machine's volume is
                low, it might be a good idea to swap it and balance one with a
                high volume to extend the life of your fleet. Speak with your
                Account Manager.
              </div>
              <table className="tw-w-full tw-divide-y tw-border tw-divide-gray-300 portalhub table">
                <thead>
                  <tr>
                    <td className="tw-border tw-text-center full-border" colSpan={2}></td>
                    <td
                      className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border"
                      colSpan={numPrevYearQrts * 2}
                    >
                      {longest.data[0].year}
                    </td>
                    <td
                      className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border"
                      colSpan={numCurrYearQrts * 2}
                    >
                      {longest.data[numPrevYearQrts].year}
                    </td>
                    <td
                      rowSpan={3}
                      className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border"
                    >
                      Total Mono
                    </td>
                    <td
                      rowSpan={3}
                      className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border"
                    >
                      Total Colour
                    </td>
                  </tr>
                  <tr>
                    <td className="tw-border tw-text-center full-border full-border" colSpan={2}></td>
                    {longest.data.map((d) => (
                      <td
                        colSpan={2}
                        className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border"
                      >
                        QTR {d.quarter}
                      </td>
                    ))}
                  </tr>
                  <tr className="tw-border-b-2 tw-border-gray-300">
                    <th className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border">
                      Serial No
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border">
                      Model Type
                    </th>
                    {longest.data.map((d) => (
                      <>
                        <th className="tw-py-3.5 tw-px-4 tw-border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border">
                          Mono
                        </th>
                        <th className="tw-py-3.5 tw-px-4 border tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell full-border">
                          Colour
                        </th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {volumeBreakdown.map((vb, i) => {
                    return (
                      <tr
                        className={`${
                          i % 2 === 0 ? "tw-bg-gray-100 full-border" : "tw-bg-white full-border"
                        }`}
                      >
                        <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                          {vb.serialNumber}
                        </td>
                        <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                          {vb.description}
                        </td>
                        {Array(longest.data.length)
                          .fill("")
                          .map((_, j) => {
                            if (vb.data[j]) {
                              return (
                                <>
                                  <td className="tw-px-3 tw-py-4 tw-text-sm tw-text-center tw-font-medium">
                                    {Number(vb.data[j].mono).toLocaleString()}
                                  </td>
                                  <td className="ptw-x-3 tw-py-4 tw-text-sm tw-text-center tw-font-medium">
                                    {Number(
                                      vb.data[j].colour
                                    ).toLocaleString()}
                                  </td>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <td></td>
                                  <td></td>
                                </>
                              );
                            }
                          })}
                        <td className="tw-px-3 tw-py-4 tw-text-sm tw-text-center tw-font-medium">
                          {Number(vb.totalMono).toLocaleString()}
                        </td>
                        <td className="tw-px-3 tw-py-4 tw-text-sm tw-text-center tw-font-medium">
                          {Number(vb.totalColour).toLocaleString()}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="portalhub pagebreak">
              <SectionHeader
                customerName={currentUser.customer[0].Name}
                section="Machine Uptime"
                smallLogoBase64={data.agilicoLogoSmall}
              />
              <div className="tw-my-6">
                <p>
                  This report is very important to us because it is core to
                  demonstrating that our unique Preventative Maintenance
                  service provides industry leading levels of uptime and
                  customer service. Were strict on ourselves too, measuring
                  uptime based on eight working hours a day and deducting
                  downtime starting from the response time to a service call
                  to the time we complete the work. We hope these figures will
                  wow you!
                </p>
              </div>
              <table className="tw-w-full tw-divide-y tw-border tw-divide-gray-300 portalhub table">
                <thead>
                  <tr className="tw-border-b-2 tw-border-gray-300">
                    <th className="tw-py-3.5 tw-px-4 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Postcode
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Model Type
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Serial No
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Location
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Calls
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-left tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Uptime %
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.reportEvents.map((row, i) => (
                    <tr
                      className={`${
                        i % 2 === 0 ? "tw-bg-gray-100 full-border" : "tw-bg-white full-border"
                      }`}
                    >
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.postCode}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.description}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.serialNumber}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.location}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.breakdowns}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.uptimePercent?.toFixed(2)}%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="portalhub pagebreak">
              <SectionHeader
                customerName={currentUser.customer[0].Name}
                section="Machine Events"
                smallLogoBase64={data.agilicoLogoSmall}
              />
              <div className="tw-my-6">
                <p>
                  When your machines run problem free, it can be easy to
                  assume that there have been no problems at all. This report
                  helps you to see how our team have been supporting your
                  business behind the scenes using Remote IT Support, or
                  onsite with Preventative Maintenance or Unscheduled Calls,
                  or Toner Deliveries. Don't forget, our Remote Print
                  Monitoring software is pro-actively managing your print
                  fleet in real-time, raising issues without you even having
                  to make a call.
                </p>
              </div>
              <table className="tw-w-full tw-divide-y tw-border tw-divide-gray-300 portalhub table">
                <thead>
                  <tr className="tw-border-b-2 tw-border-gray-300">
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Postcode
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 vlg:table-cell">
                      Model Type
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Serial No
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Location
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Courtsey Calls
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Breakdowns
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      IT Calls
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Return To Fit
                    </th>
                    <th className="tw-py-3.5 tw-px-4 tw-text-center tw-text-sm tw-font-semibold tw-text-gray-900 tw-lg:table-cell">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.reportEvents.map((row, i) => (
                    <tr
                      className={`${
                        i % 2 === 0 ? "tw-bg-gray-100 full-border" : "tw-bg-white full-border"
                      }`}
                    >
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.postCode}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.description}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.serialNumber}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-sm tw-font-medium">
                        {row.location}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-center tw-text-sm tw-font-medium">
                        {row.courtesyVisits}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-center tw-text-sm tw-font-medium">
                        {row.breakdowns}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-center tw-text-sm tw-font-medium">
                        {row.hdCalls}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-center tw-text-sm tw-font-medium">
                        {row.rtf}
                      </td>
                      <td className="tw-px-3 tw-py-4 tw-text-center tw-text-sm tw-font-medium">
                        {row.courtesyVisits +
                          row.breakdowns +
                          row.hdCalls +
                          row.rtf}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="portalhub pagebreak">
              <SectionHeader
                customerName={currentUser.customer[0].Name}
                section="Response Times"
                smallLogoBase64={data.agilicoLogoSmall}
              />
              <div className="tw-my-6">
                <p>
                  At Agilico, we provide our Preventative Maintenance calls to
                  attend to and care for your machines at regular intervals.
                  But sometimes emergencies happen and an Unscheduled Call is
                  required to quickly resolve the issue and get you happily
                  printing again. Our engineer response times are achieved
                  through the clever coordination of our service department
                  with engineers in the field to attend to your needs within
                  industry leading times, wherever you are.
                </p>
              </div>
              <div className="tw-mx-auto tw-block tw-w-[28%]">
                <Pie
                  options={{
                    responsive: true,
                  }}
                  data={getFixTimeData()}
                />
              </div>
              <div>
                <table className="tw-mt-6 tw-w-full tw-text-sm tw-text-gray-600 tw-text-center tw-border portalhub table">
                  <tr>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-ag-default" /> Within
                        1 Hour
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-ag-green" /> Within 2
                        Hours
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-[#607E8F]" /> Within 3
                        Hours
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                      <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-[#51F590]" /> Within 4
                        Hours
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        <div className="tw-h-4 tw-w-4 tw-mr-4 tw-bg-[#97AFBD]" /> Over 4
                        Hours
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        {data.reportFixTimes.reduce(
                          (acc, val) => (acc += val.within1Hour),0)}
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        {data.reportFixTimes.reduce(
                          (acc, val) => (acc += val.within2Hours),0)}
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        {data.reportFixTimes.reduce(
                          (acc, val) => (acc += val.within3Hours),0)}
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        {data.reportFixTimes.reduce(
                          (acc, val) => (acc += val.within4Hours),0)}
                      </div>
                    </td>
                    <td className="tw-border tw-px-3 tw-py-1 full-border">
                      <div className="tw-flex tw-justify-center tw-items-center">
                        {data.reportFixTimes.reduce((acc, val) => (acc += val.over4Hours),0)}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

