import { Fragment } from 'react';
import moment from "moment";

export default function SnoozeContainer({ type, data }) {
  const days = `${(moment(data.snoozeUntilDate).diff(moment().subtract(10, 'minutes'), 'days') <= 1) ? 'day' : 'days'}`;
  const dateTitle = `Snoozed (${moment(data.snoozeUntilDate).utc(true).format('DD/MM/YYYY')})`;
  const daysTitle = `Snoozed (${data.daysEmpty} ${days})`;
  return <Fragment>
    {type === 1 && <div title={dateTitle} className="flex-container center row around width-100">
      <div className="flex-item center text-white width-100">Snoozed</div>
    </div>}
    {type === 2 && <div title={daysTitle} className="flex-container center around width-100">
      <div className="flex-item center text-white width-100">Snoozed</div>
    </div>}
  </Fragment>
}