import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch } from 'react-redux';
import UILIB from 'components';
import Axios from 'classes/axios';
import { setFilterVars, tableContainer, actionOpenNotesDrawer, getYesNo } from '../functions';
import Modules from '../refurb/modules';

export default function AvailableDevicesTable({filter, selected}) {
    const defaultReserveDialogue = { refurbId: '', serialNo: '', show: false };
    const defaultDeleteDialogue = { refurbIds: [], count: 0, show: false };
    const [remoteRender, setRemoteRender] = useState(false);
    const [localRender, setLocalRender] = useState(false);
    const [reserving, setReserving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [showDeleteDialogue, setShowDeleteDialogue] = useState(defaultDeleteDialogue);
    const [showReserveDialogue, setShowReserveDialogue] = useState(defaultReserveDialogue);
    
    const refurbTableHeader = [
        { label: '', value: 'equipmentID', type: 'delete', filtering: false, minWidth: 30, maxWidth: 30 },
        { label: 'Location', value: 'deviceLocation', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
        { label: 'Make', value: 'make', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
        { label: 'Model', value: 'model', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
        { label: 'Serial No', value: 'serialNumber', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
        // { label: 'Attached', value: 'attachments', type: 'string', filtering: true, minWidth: 250, maxWidth: 250 },
        // { label: 'Mono Reading', value: 'monoReading', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
        // { label: 'Colour Reading', value: 'colourReading', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
        { label: 'Value', value: 'value', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
        { label: 'Reserve for customer', value: 'reserveforcustomer', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
        // { label: "Notes", value: "notes", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
    ];

    const entityData = useRef({});
    const tablePageDefaults = { deleteEnabled: true, paging: { pages: [25, 50, 75, 100, 300], pageSelected: 20, limit: 20, offset: 0, orderBy: 'Location', orderDir: 'DESC' } };
    

    const tableReserveRecord = async (refurbId, serialNo) => {
        setShowReserveDialogue({ refurbId, serialNo, show: true });
    };

    const tableDeleteRefurb = async (event) => {
        setShowDeleteDialogue({ Ids: event, count: event.length, show: true });
    };

    const actionDeleteRefurb = async (Ids) => {
        setDeleting(true);
        await Axios.delete(`/entities/serviceAdmin_refurbs`, { data: { Ids: Ids } });
        entityData.current.result = entityData.current.result.filter(x => !Ids.map(m => Number(m)).includes(Number(x.id)));
        setShowDeleteDialogue(defaultDeleteDialogue);
        setDeleting(false);
        setLocalRender(!localRender);
    };

    const actionReserveRefurb = async (data, field) => {
        setReserving(true);
        const refurbID = data.id;
        //const baseData = { tonerID: tonerId, rowVersion: tonerResult.result[0].rowVersion, isSnoozed: 0, isSnoozedQueued: 0, tonerReleaseDate: moment().utc(true).format("YYYY-MM-DD HH:mm:ss"), daysLeft: null, snoozedBy: null, snoozeUntilDate: null };
        setReserving(false);
        setLocalRender(!localRender);
    };

    const getCustomers = async(name, token = {}) =>  {
        const results = await Axios.get(`/entities/customers/?&$filter=AltName like ${name}&$select=id and AltName`, token).then(api => api.data.result);
        return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
        const expandArray = ['notes','attachments','meters'];
        const queryLocal = query !== null ? query : '';
        const pagingLocal = limit !== null && offset !== null ? `&$limit=${limit}&$offset=${offset}` : '';
        const orderLocal = orderBy !== null && orderDir !== null ? `&$order=${orderBy}&$direction=${orderDir}` : '';
        const filterButtons = setFilterVars(filter);
        const buttonAppend = (filter.button.length) && (filterButtons !== '') ? ` ${filterButtons} ` : '';
        const storeSelected = (selected.length) ? ` ${selected.join(',')} ` : '';
        entityData.current = await Axios.get(`/entities/equipments/getAvailableDevices/${storeSelected}/?&$filter=${[buttonAppend].filter(x => x).join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
        
        return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }; 

    async function constructTable(availableDevices) {

        console.log(availableDevices);

        const tableData = availableDevices.map(row => {
    
          //const b_hasNotes = Boolean(row.notes && row.notes.length);
          //const attachments = <Modules.Attachments row={row} />
          //const monoReadings = <Modules.MonoReadings row={row} />
          //const colourReadings = <Modules.ColourReadings row={row} />
          //const fn_notes = actionOpenNotesDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: remoteRender, set: setRemoteRender });
          //const notesColour = (b_hasNotes) ? 'colour colour background-6 red' : 'colour lightGrey';
          const reserve = <UILIB.TableContainer data={<UILIB.Button value="Reserve for Customer" className="table small green" onClick={async () => await tableReserveRecord(row.refurbID, row.serialNumber)}/>} className="width-100 height-100" />
    
          return {
            equipmentID: { value: row.ID, raw: row.ID },
            deviceLocation: {value: row.Location, raw: row.Location},
            make: {value: row.Make, raw: row.Make},
            model: {value: row.Model, raw: row.Model},
            serialNumber: {value: row.SerialNo, raw: row.SerialNo},
            // attachments: {value: attachments, raw: attachments},
            // monoReading: {value: monoReadings, raw: monoReadings},
            // colourReading: {value: colourReadings, raw: colourReadings},
            value: {value: '£' + row.Cost, raw: row.Cost},
            reserveforcustomer: {value: reserve, raw: row.reserveforcustomer},
            //notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes }
          };
        });
        return tableData;
    }

    return (
        <Fragment>
            {showDeleteDialogue.show && <UILIB.MessageBox 
            header={'Delete Records'} 
            loading={deleting}
            text={`Click OK to DELETE the selected ${(showDeleteDialogue.count) > 1 ? 'Records' : 'Record'}`} 
            onConfirm={async () => await actionDeleteRefurb(showDeleteDialogue.refurbIds)} 
            onCancel={() => setShowDeleteDialogue({ ...showDeleteDialogue, show: false })} />}
            {showReserveDialogue.show && <UILIB.MessageSelectBox 
            header={'Reserve for customer'} 
            loading={reserving}
            text={`Select customer and click to reserve ${(showReserveDialogue.serialNo)}`} 
            onConfirm={actionReserveRefurb} 
            onCancel={() => setShowReserveDialogue({ ...showReserveDialogue, show: false })} 
            selectQuery={getCustomers} />}
            <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">
            <UILIB.Paper className="width-100">
                <div>
                <UILIB.TableNew 
                    name="failedEmailList" 
                    className="small" 
                    overflowX="auto" 
                    overflowY="hidden" 
                    header={refurbTableHeader} 
                    localQuery={() => constructTable(entityData.current && entityData.current.result ? entityData.current.result : [])}
                    localRender={[localRender]}
                    remoteQuery={getTableData} 
                    remoteRender={[selected, filter, remoteRender]} 
                    defaultSettings={tablePageDefaults} 
                    deleteQuery={async (ev) => await tableDeleteRefurb(ev)}
                    />
                </div>
            </UILIB.Paper>
            </div>
        </Fragment>
    )
}