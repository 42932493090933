import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { downloadFileHandler } from 'classes/download';
import moment from "moment";


export default function GenerateStocksReport() {

  const emptyError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(emptyError);
  const [loading, setLoading] = useState(false);
  const exceptions = [400,404];
 
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const handleGenerateCSV = async () => {
    try {
      setLoading(true);
      const response = await Axios.get(`/entities/workflow/actionGenerateStocksReport`);
      if(exceptions.includes(response.status)) {
        if(response.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(response.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setBannerError(emptyError);
        downloadFileHandler(`StocksReport_${moment().utc(true).format('YYYYMMDD_HHMMss')}`, 'csv', response.data.result);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBannerError({ error: true, message: exceptionError });
    }
  }

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className="flex-item end width-100">
        <UILIB.Button loading={loading} disabled={loading} value="Export All Site Stocks" className="green" onClick={async () => await handleGenerateCSV()} />
      </div>
      {bannerError.error && <div className="flex-container end width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>
}