import { useState } from 'react';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';
import EmailDataDrawer from '../drawer/emailDataDrawer';
const format = require('../../../../classes/format');

export default function FailedEmails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [renderMe, setRenderMe] = useState(true);

  const [dates, setDates] = useState({
    startDate: moment().utc(true).format('YYYY-MM-DD').toString(),
    endDate: moment().utc(true).format('YYYY-MM-DD').toString()
  });

            

  const updateDates = (newDate) => {
    let ourDates = JSON.parse(JSON.stringify(dates));
    ourDates[newDate.target.name] = newDate.target.value;
    setDates(ourDates);
  }


  const delete_onClick = async (row) => {
    if(window.confirm(`Delete ${row.emailID}\nAre you sure?`)) { 
        await Axios.delete(`/entities/failedEmail/${row.emailID}`);
        getTableData();
        setRenderMe(!renderMe);
        console.log("Deleted!");
    }
    else {
        console.log("No delete");
    };
}

  function renderTable(userData) {
    const tableData = userData.map((row) => {
      const deleteButton = (
        <div align='center'>
          <UILIB.Button   key={row.emailID} className="button table width-100 red" name="deleteSelect" value="Delete" onClick={() => delete_onClick(row)} />
        </div>
      );
      const viewButtons = (
        <div align="center">
          <UILIB.Button key={row.courierID} className="button table width-50" name="editSelect" value="View" onClick={() => onViewClicked(row)} />
        </div>
      );
      return {
        emailID: { value: row.emailID, raw: row.emailID },
        subject: { value: row.subject, raw: row.subject },
        body: { value: row.body, raw: row.body },
        dateAdded: {
          value: format.formatDateTime(row.dateAdded, 'DD/MM/YYYY HH:mm:ss'),
          raw: row.dateAdded,
        },
        senderEmail: { value: row.senderEmail, raw: row.senderEmail },
        bodyText: { value: row.bodyTxt, raw: row.bodyTxt },
        messageID: { value: row.messageID, raw: row.messageID },
        hasAttachments: { value: row.hasAttachments, raw: row.hasAttachments },
        kpaxProcessed: { value: row.kpaxProcessed, raw: row.kpaxProcessed },
        view: { value: viewButtons, raw: '' },
        delete: { value: deleteButton, raw: '' },
      };
    });
    return tableData;
  }

  const onViewClicked = async (row) => {
    dispatch(
      setDrawer({
        open: true,
        content: <EmailDataDrawer onClose={emailDataDrawerClose} data={row} />,
        width: '700px',
      })
    );
  };

  const emailDataDrawerClose = async () => {
    dispatch(setDrawer({ open: false, content: null }));
    setRenderMe(!renderMe);
  };

  // Email Table Headers
  const failedEmailsTableHeader = [
    {
      label: 'Date Added',
      value: 'dateAdded',
      type: 'datetime',
      filtering: true,
      minWidth: 100,
    },
    {
      label: 'Email ID',
      value: 'emailID',
      type: 'number',
      filtering: true,
      minWidth: 100,
    },
    {
      label: 'Subject',
      value: 'subject',
      type: 'string',
      filtering: true,
      minWidth: 250,
    },
    {
      label: 'Body',
      value: 'body',
      type: 'string',
      filtering: true,
      minWidth: 250,
    },
    {
      label: 'Sender Email',
      value: 'senderEmail',
      type: 'string',
      filtering: true,
      minWidth: 100,
    },
    {
      label: 'Message ID',
      value: 'messageID',
      type: 'string',
      filtering: true,
      minWidth: 100,
    },
    {
      label: 'Has Attachments',
      value: 'hasAttachments',
      type: 'string',
      filtering: true,
      minWidth: 100,
    },
    {
      label: 'Kpax Processed',
      value: 'kpaxProcessed',
      type: 'number',
      filtering: true,
      minWidth: 100,
    },
    {
      label: '',
      value: 'view',
      type: '',
      filtering: false,
      minWidth: 30,
      maxWidth: 100,
    },
    {
      label: '',
      value: 'delete',
      type: '',
      filtering: false,
      minWidth: 30,
      maxWidth: 100,
    },    
  ];

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
    setLoading(true);
    const apiData = {};
    const queryLocal = query !== null ? query : '';
    const pagingLocal = limit !== null && offset !== null ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = orderBy !== null && orderDir !== null ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    apiData.failedEmails = await Axios.get(`/entities/failedEmails//?&$filter=processed eq 0 and (dateAdded gte ${dates.startDate} and dateAdded lte ${dates.endDate}) ${queryLocal}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then((api) => api.data);

    setLoading(false);
    return {      
      tableData: await renderTable(apiData.failedEmails.result),
      nonePaged: apiData.failedEmails.nonePaged,
    };
  };

  const tablePageDefaults = { paging: { pages: [25, 50, 75, 100, 300], pageSelected: 20, limit: 20, offset: 0, orderBy: 'createdAt', orderDir: 'DESC' } };

  return (
    <Fragment>
      <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">
        <UILIB.Paper className="width-100 mar-b15">
          <h1>Failed Emails</h1>
          <div class="flex-container row">
            <div class="flex-item start flex-grow-1 ">
              <UILIB.Button value="Back" onClick={() => history.push('/')} className="button height-100 grey"></UILIB.Button>
            </div>
            <p className="flex-item align-center mar-r10">Date Filter:</p>
              <UILIB.TextInput className="textInput input flex-item align-center" type="date" name="startDate" outerclassNname="mar-r10" value={dates.startDate} onChange={updateDates} />
              <UILIB.TextInput className="textInput input flex-item align-center" type="date" name="endDate" outerclassNname="mar-r10" value={dates.endDate} onChange={updateDates} />
              <div className='flex-item'> 
                <UILIB.Button loading={loading} value={'Action'} onClick={ () => {setRenderMe(!renderMe)}} />
              </div>            
          </div>
        </UILIB.Paper>
        <UILIB.Paper className="width-100">
          <h3 className="flex-item start width-100 text-16 mar-t5">Failed Emails</h3>
          <div>
            <UILIB.TableNew name="failedEmailList" className="small" overflowX="auto" overflowY="hidden" header={failedEmailsTableHeader} remoteQuery={getTableData} remoteRender={[renderMe]} defaultSettings={tablePageDefaults} />
          </div>
        </UILIB.Paper>
      </div>
    </Fragment>
  );
}
