import { useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Axios from 'classes/axios';
import UILIB from 'components';
import Modules from '../Modules';
import moment from "moment";
import { tableContainer, actionOpenDrawer } from '../../functions'


export default function SiteStocksTable({ entityLookup, siteDeviceRender, setSiteDeviceRender, siteStockRender }) {

  const headerData = [
    { label: '', value: 'selected', type: 'string', filtering: false, minWidth: 20, maxWidth: 20 },
    { label: 'Group Name', value: 'groupName', type: 'string', filtering: false, minWidth: 350, maxWidth: 350 },
    { label: 'Dev Count', value: 'totalDevices', type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: 'Black', value: 'black', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'Cyan', value: 'cyan', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'Magenta', value: 'magenta', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'Yellow', value: 'yellow', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'Waste', value: 'waste', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'Notes', value: 'notes', type: 'string', filtering: false, minWidth: 30, maxWidth: 30 }];

  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const tablePageDefaults = { paging: { pages: [10, 20, 50, 100, 200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'totalDevices', orderDir: 'ASC' } };
  const defaultDialogue = { count: 0, show: false };
  const entityData = useRef({});
  const groupCheckbox = useRef({});
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [showDialogue, setShowDialoge] = useState(defaultDialogue);
  const [localRender, setLocalRender] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const renders = { device: siteDeviceRender, setDevice: setSiteDeviceRender, local: localRender, setLocal: setLocalRender };

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];

  const updateGroup = async (event) => {
    const result = await Axios.put(`/entities/serviceAdmin_siteStore/${event.row.custID}/${event.row.rowID}`, { entity: { ...event.row, [event.field]: event.value, supression: true } });
    if(result.data.success) {
      const found = entityData.current.groups.result.findIndex(x => x.rowID === event.row.rowID);
      entityData.current.groups.result[found] = { 
      ...entityData.current.groups.result[found], 
      updatedByTxt: account.displayName,
      updatedAt: moment().utc(true).format('YYYY-MM-DD HH:MM:ss') }; }
    if (event.global) setSiteDeviceRender(!siteDeviceRender);
    setLocalRender(!localRender);
  };

  const deleteGroup = async (event) => {
    try {
      setDeleting(true);
      const rowIdArray = Array.from(new Set(Object.keys(event).filter(x => event[x]))).map(x => Number(x));
      const deleteData = entityData.current.groups.result.filter(x => rowIdArray.includes(Number(x.rowID)));
      const result = await Axios.delete(`/entities/serviceAdmin_siteStores`, { data: { entity: deleteData } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        entityData.current.groups.result = entityData.current.groups.result.filter(x => !rowIdArray.includes(Number(x.rowID)));
        setShowDialoge(defaultDialogue);
        setSiteDeviceRender(!siteDeviceRender);
        setLocalRender(!localRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setDeleting(false);
  };

  const updateCheckbox = (rowID) => {
    groupCheckbox.current = { ...groupCheckbox.current, [rowID]: !groupCheckbox.current[rowID] };
    setLocalRender(!localRender);
  };

  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      const b_hasNotes = Boolean(row?.notes?.length);
      const notesColour = (b_hasNotes) ? 'colour background-6 red' : 'colour background lightGrey';
      const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawerStocks', row, dispatch, true);

      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox disabled={processing} checked={groupCheckbox.current[row.rowID]} type='checkbox' onChange={() => updateCheckbox(row.rowID)} />} /> },
        groupName: { value: <Modules.GroupContainer processing={processing} setProcessing={setProcessing} localRender={localRender} setLocalRender={setLocalRender} data={row} onChange={async (ev) => await updateGroup(ev)} /> },
        totalDevices: { value: <UILIB.TableContainer data={row.totalDevices} />, raw: row.totalDevices },
        black: { value: <Modules.TonerContainer processing={processing} setProcessing={setProcessing} localRender={localRender} setLocalRender={setLocalRender} bgCol="grey" txtCol="white" data={row} stockName="black" maxName="blackMin" onChange={async (ev) => await updateGroup(ev)} />, raw: '' },
        cyan: { value: <Modules.TonerContainer processing={processing} setProcessing={setProcessing} localRender={localRender} setLocalRender={setLocalRender} bgCol="cyan" txtCol="black" data={row} stockName="cyan" maxName="cyanMin" onChange={async (ev) => await updateGroup(ev)} />, raw: '' },
        magenta: { value: <Modules.TonerContainer processing={processing} setProcessing={setProcessing} localRender={localRender} setLocalRender={setLocalRender} bgCol="magenta" txtCol="black" data={row} stockName="magenta" maxName="magentaMin" onChange={async (ev) => await updateGroup(ev)} />, raw: '' },
        yellow: { value: <Modules.TonerContainer processing={processing} setProcessing={setProcessing} localRender={localRender} setLocalRender={setLocalRender} bgCol="yellow" txtCol="black" data={row} stockName="yellow" maxName="yellowMin" onChange={async (ev) => await updateGroup(ev)} />, raw: '' },
        waste: { value: <Modules.TonerContainer processing={processing} setProcessing={setProcessing} localRender={localRender} setLocalRender={setLocalRender} bgCol="waste" txtCol="black" data={row} stockName="waste" maxName="wasteMin" onChange={async (ev) => await updateGroup(ev)} />, raw: '' },
        notes: { value: tableContainer(fn_notes, 'icon-pencil', (b_hasNotes) ? 'View Notes' : 'Notes Unavailable', notesColour, true), raw: b_hasNotes }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const expandArray = ['notes'];

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    if (entityLookup.hasOwnProperty('customerId')) {
      const customerId = entityLookup['customerId'];
      entityData.current.groups = await Axios.get(`/entities/serviceAdmin_siteStores/getSummary/?&$filter=custID eq ${customerId} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
      entityData.current.customerId = entityLookup['customerId'];
    }

    if (entityLookup.hasOwnProperty('equipmentId')) {
      const equipmentId = entityLookup['equipmentId'];
      entityData.current.equipment = await Axios.get(`/entities/equipment/${equipmentId}/?&$select=CustomerId and SerialNumber`, { cancelToken: cancelToken.token }).then(res => res.data.result[0]);
      entityData.current.groups = await Axios.get(`/entities/serviceAdmin_siteStores/getSummary/?&$filter=custID eq ${entityData.current.equipment.CustomerId} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
      entityData.current.customerId = entityData.current.equipment.CustomerId;
    }

    if (!entityData.current || !entityData.current.groups || !Array.isArray(entityData.current.groups.result)) return false;

    let checked = {};
    if (entityData.current.groups && entityData.current.groups.result) {
      entityData.current.groups.result.forEach(x => { return checked = { ...checked, [x.rowID]: false } })
      groupCheckbox.current = checked;
    }

    setLoaded(true);

    return { tableData: await constructTable(entityData.current.groups.result), raw: entityData.current.groups.result, nonePaged: entityData.current.groups.nonePaged };
  }

  return <Fragment>
    {showDialogue.show && <UILIB.MessageBox
      header={'Delete Groups'}
      text={`Click OK to DELETE the selected ${(showDialogue.count) > 1 ? 'Groups' : 'Group'}`}
      onConfirm={async () => await deleteGroup(groupCheckbox.current)}
      onCancel={() => setShowDialoge({ ...showDialogue, show: false })} 
      loading={deleting} 
      errored={bannerError} />}
    <UILIB.Paper className='flex-item row width-100 flex-1 mar-b20'>
      <div className="flex-container row width-100">
        <div className='flex-item start flex-grow-1'><h4>Site Stocks</h4></div>
        <UILIB.Button
          value="Delete"
          className="button secondary mar-b5"
          disabled={!Object.values(groupCheckbox.current).some(x => x)}
          onClick={() => setShowDialoge({ count: Object.values(groupCheckbox.current).filter(x => x).length, show: true })} />
        <UILIB.Button
          value="Create"
          className="button secondary mar-b5"
          disabled={!loaded}
          onClick={() => actionOpenDrawer('CreateGroupDrawer', entityData.current, dispatch, true, renders)} />
      </div>
      <div className="width-100">
        <UILIB.TableNew
          name="SiteStocks"
          className='small'
          overflowX='auto'
          overflowY='hidden'
          header={headerData}
          localQuery={() => constructTable((entityData.current && entityData.current.groups) ? entityData.current.groups.result : [])}
          localRender={[localRender, processing]}
          remoteQuery={getTableData}
          remoteRender={[entityLookup, siteStockRender]}
          defaultSettings={tablePageDefaults} />
      </div>
    </UILIB.Paper>
  </Fragment>
}