import React from 'react';

export default React.forwardRef((props, ref) => {

    let outerclassNname = (props.outerclassNname) ? props.outerclassNname : 'flex-item flex-grow-1';
    let innerClassName = `flex-item width-100 ${(props.className) ? props.className : ''}`;
    let errored = (props?.errored) ? props.errored : { messageText: "", option: 0 };

    if (props.button) outerclassNname += " withButton ";

    return <div className={outerclassNname}>
        <input {...props} onWheel={(ev) => ev.currentTarget.blur()} ref={ref} disabled={props.disabled} className={innerClassName + (errored.error && errored.option === 0 && !props.disabled ? " errored border " : "")} />
        {errored.error && errored.option === 1 && <div className="errored message">{errored.messageText}</div>}
        {props.button && <button onClick={props.onClick}>{props.button}</button>}
    </div >
})