import UILIB from 'components';
import moment from "moment";
import ViewAlertsDrawer from './tonerLookup/drawer/viewAlertsDrawer';
import ViewNotesDrawer from './tonerLookup/drawer/viewNotesDrawer';
import ViewLogsDrawer from './tonerLogging/drawer/viewLogsDrawer';
import ViewProcessedRobotDrawer from './tonerLogging/drawer/viewRobotDrawer';
import ViewRobotDrawer from './failedBot/drawer/viewRobotDrawer';
import ViewSendVoDrawer from './sendToVantageOnline/drawer/viewSendVoDrawer';
import CreateGroupDrawer from './customerRequests/modules/createGroupDrawer';
import { setDrawer } from 'store/actions';
import RequestsModules from './customerRequests/Modules';
import StockRequestModules from './customerSiteStockRequests/Modules';
import SnoozeContainer from './tonerLookup/snoozeContainer';
import ManualOrderContainer from './tonerLookup/manualOrderContainer';


export function setFilterVars(context, filter) {
  if (context === 'processed') return '';
  if (filter.button === 'Active') {
    return ` ( ( isSnoozed is null or isSnoozed eq 0 ) and ( isBatched is null or isBatched eq 0 ) ) `;
  }
  if (filter.button === 'Over 3 Days') {
    return ` ( tonerDate lt ${moment().subtract(3, 'days').utc(true).format('YYYY-MM-DD HH:mm:ss.SSS')} ) `;
  }
  if (filter.button === 'Urgent') {
    return ` urgentOrder ne 0 `;
  }
}

export function setProcessedVars(filter, start, end) {
  const processedRange = ` ( tonerDespatchedDateCast gte ${moment(start).utc(true).format('YYYY-MM-DD')} and tonerDespatchedDateCast lte ${moment(end).utc(true).format('YYYY-MM-DD')} ) `;
  const deleteRange = ` ( deletedAtCast gte ${moment(start).utc(true).format('YYYY-MM-DD')} and deletedAtCast lte ${moment(end).utc(true).format('YYYY-MM-DD')} ) `;
  if(filter.button === 'Processed') {
    return [processedRange, ' ( isProcessed eq 1 and isDeleted eq 0 ) '].join(' and ');
  } else if(filter.button === 'Deleted') {
    return [deleteRange, ' ( isProcessed eq 0 and isDeleted eq 1 ) '].join(' and ');
  } else {
    const datesMerged = [processedRange, deleteRange].join(' or ');
    return [`( ${datesMerged} )`, ' ( isProcessed eq 1 or isDeleted eq 1 ) '].join(' and ');
  }
}

export function setFilterVarsQueued(filter) {
  if (filter.button === 'Batched') {
    return ' ( isBatchedQueued eq 1 and isProcessed eq 0 and isDeleted eq 0 ) ';
  } else if (filter.button === 'Snoozed') {
    return ' ( isSnoozedQueued eq 1 and isProcessed eq 0 and isDeleted eq 0 ) ';
  } else {
    return ' ( ( isBatchedQueued eq 1 or isSnoozedQueued eq 1 ) and isProcessed eq 0 and isDeleted eq 0 ) ';
  }
}

export function setFilterVarsFailedBot(start, end) {
  return ` ( tonerDateCast gte ${moment(start).utc(true).format('YYYY-MM-DD')} and tonerDateCast lte ${moment(end).utc(true).format('YYYY-MM-DD')} ) `;
}

export function setFilterVarsProcessedOrders(start, end) {
  const processedRange = ` ( tonerDateCast gte ${moment(start).utc(true).format('YYYY-MM-DD')} and tonerDateCast lte ${moment(end).utc(true).format('YYYY-MM-DD')} ) `;
  return [`${processedRange}`, ' ( ( tonerDespatched eq 1 or tonerDespatched eq 9 or failedBotTests eq 1 ) or ( isProcessed eq 1 or isDeleted eq 1 or failedBotTests eq 1 ) ) '].join(' and ');
}

export function setFilterVarsStockRequests(filter, filters) {
  const dateAddedRange = ` ( dateAdded gte ${moment(filters.dateAdded.startDate).utc(true).format('YYYY-MM-DD 00:00')} and dateAdded lte ${moment(filters.dateAdded.endDate).utc(true).format('YYYY-MM-DD 23:59')} ) `;
  if (filter.button === 'Active') {
    return [`${dateAddedRange}`, ' warnAuditNotDue eq 0 and status eq 0 '].join(' and ');
  }
  if (filter.button === 'Archived') {
    return [`${dateAddedRange}`, ' warnAuditNotDue eq 0 and ( status eq 1 or status eq 2 ) '].join(' and ');
  }
  return dateAddedRange;
}

export function setFilterVarsVoRequest(filter) {

  if (filter.button === 'Failed') return ' ( status eq 0 or status eq 2 ) and apiType eq VOTONER and ( apiResponse ne 200 and apiResponse ne 204 ) ';
  if (filter.button === 'Completed') return ' status eq 1 and rowVersion isn null and apiType eq VOTONER and ( apiResponse eq 200 or apiResponse eq 204 ) ';

  return ` status eq 0 or status eq 1 or status eq 2 `;
}

export function setFilterVarsVoCurrentRequest() {

  return ` ( isInclusive eq 1 or isChargable eq 1 )
    and isDeleted eq 0 
    and isSnoozed eq 0 
    and isProcessed eq 0 
    and isOnStop eq 0 
    and tonerDespatched eq 0 
    and isBatchedQueued eq 0 
    and isSnoozedQueued eq 0 `;
}

export function getDefaultFiltersStockRequests() {
  return { 
    button: 'Active',
    dateAdded: {
      startDate: moment().subtract(10,"year").utc(true).format('YYYY-MM-DD').toString(),
      endDate: moment().utc(true).format('YYYY-MM-DD').toString()
    }
  };
}

export function getBGColour(row) {
  if (row.urgentOrder > 0) return 'colour background-4 limeGreen';
  if (Boolean(row.over3Days)) return 'colour background-2 purple';
  return '';
}

export function getMissingStoreColour(row, groupId) {
  if (row.storeCount === 0) return 'colour background-4 red';
  if (row.storeCount > 0 && (groupId === -1 || !groupId)) return 'colour background-4 orange';
  return '';
}

export function getYesNo() {
  return [{ value: -1, label: "Select" }, { value: 0, label: "No" }, { value: 1, label: "Yes" }]
}

export function getBatching() {
  return [{ value: -1, label: "Select" }, { value: 0, label: "No Batching" }, { value: 1, label: "Batch Weekly" }]
}

export function getTonerTypes() {
  return [{ value: -1, label: "Select" }, { value: 0, label: "Normal Toners" }, { value: 1, label: "Enable HY Toners" }]
}

export function getDeviceNeedsToner() {
  return [
    { value: -1, label: "Select" }, 
    { value: 0, label: "No" }, 
    { value: 2, label: "Urgent order - machine down (FOC)" }, 
    { value: 1, label: "Urgent order: customer has requested delivery upgrade and agreed to charges - PRE12 - £20" },
    { value: 3, label: "Urgent order: customer has requested delivery upgrade and agreed to charges - PRE1030 - £30" }]
}

export function consumableChip(tonerType) {
  if (tonerType === 'black') return <UILIB.Chip toolTip={'Black'} className="table colour background-8 black text-small text-white width-100" value="Black" />
  if (tonerType === 'cyan') return <UILIB.Chip toolTip={'Cyan'} className="table colour background-8 cyan text-small text-black width-100" value="Cyan" />
  if (tonerType === 'magenta') return <UILIB.Chip toolTip={'Magenta'} className="table colour background-8 magenta text-small text-black width-100" value="Magenta" />
  if (tonerType === 'yellow') return <UILIB.Chip toolTip={'Yellow'} className="table colour background-8 yellow text-small text-black width-100" value="Yellow" />
  if (tonerType === 'waste') return <UILIB.Chip toolTip={'Waste'} className="table colour background-8 waste text-small width-100" value="Waste" />
  if (tonerType === 'staples') return <UILIB.Chip toolTip={'Staples'} className="table colour background-8 lightGrey text-small text-black width-100" value="Staples" />
  if (tonerType === 'bookletstaples') return <UILIB.Chip toolTip={'Booklet Staples'} className="table colour background-8 lightGrey text-small text-black width-100" value="Booklet Staples" />
  if (tonerType === 'printheads') return <UILIB.Chip toolTip={'WF Cartridge'} className="table colour background-8 red text-small text-black width-100" value="WF Cartridge" />
}

export function statusChip(status, data, toolTip) {
  if (status === 'Active') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 orange text-small width-100" value={"Active"} />
  if (status === 'Batched') return <UILIB.Chip toolTip={toolTip} className="table batched text-small width-100" value={"Batched"} />
  if (status === 'untilDate') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 royalBlue text-small text-black width-100" value={<SnoozeContainer type={1} data={data} />} />
  if (status === 'untilEmpty') return <UILIB.Chip toolTip={toolTip} className="colour background-8 royalBlue text-small text-black width-100" value={<SnoozeContainer type={2} data={data} />} />
  if (status === 'Snoozed') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 royalBlue text-small text-black width-100" value={<SnoozeContainer type={1} data={data} />} />
  if (status === 'Failed') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 red text-small width-100" value="Failed" />
  if (status === 'Processed') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 green outline text-small width-100" value="Processed" />
  if (status === 'Deleted') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 red text-small width-100" value="Deleted" />
  if (status === 'FailedBot') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 orange text-small width-100" value={"Failed Bot"} />
  if (status === 'OnStop') return <UILIB.Chip toolTip={toolTip} className="table colour background-8 red outline text-small width-100" value="OnStop" />
}

export function voRequestChip(value, toolTip) {
  if (value === 0 || value === 2) return <UILIB.Chip toolTip={'Failed'} className="table colour background-8 red text-small text-white width-100" value="Failed" />
  if (value === 1) return <UILIB.Chip toolTip={'Completed'} className="table colour background-8 green text-small text-black width-100" value="Completed" />
}

export function stockRequestChip(requestType, toolTip) {
  if (requestType === 'Active') return <UILIB.Chip toolTip={'Active'} className="table colour background-8 orange text-small text-white width-100" value="Active" />
  if (requestType === 'Archived') return <UILIB.Chip toolTip={'Archived'} className="table colour background-8 purple text-small text-black width-100" value="Archived" />
}

export function dispatchedChip(data, toolTip) {
  if (data.Reference.substring(0, 2) === 'SR') return <UILIB.Chip toolTip={'Return'} className="table colour background-8 orange text-small text-white width-100" value="Return" />
  if (data.CompletedDate === null) return <UILIB.Chip toolTip={'In Process'} className="table colour background-8 red text-small text-black width-100" value="In Process" />
  if (data.CompletedDate !== null) return <UILIB.Chip toolTip={'Sent'} className="table colour background-8 green text-small text-black width-100" value="Sent" />
}

export function loggingChip(value) {
  const colour = (value === 'Yes') ? 'green' : 'red';
  return <UILIB.Chip toolTip={value} className={`table colour background-8 ${colour} text-small text-white width-100`} value={value} />
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if (!enabled) return false;
  const selector = (type, data) => {
    if (type === 'ViewNotesDrawer') return <ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'ViewNotesDrawerStocks') return <RequestsModules.NotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} />
    if (type === 'ViewAlertsDrawer') return <ViewAlertsDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} />
    if (type === 'CreateGroupDrawer') return <CreateGroupDrawer entity={data} sync={sync} onClose={() => dispatch(setDrawer({ open: false, content: null }))} />
    if (type === 'ViewLogsDrawer') return <ViewLogsDrawer entity={data} sync={sync} onClose={() => dispatch(setDrawer({ open: false, content: null }))} />
    if (type === 'ViewProcessedRobotDrawer') return <ViewProcessedRobotDrawer entity={data} sync={sync} onClose={() => dispatch(setDrawer({ open: false, content: null }))} />
    if (type === 'ViewRobotDrawer') return <ViewRobotDrawer entity={data} sync={sync} onClose={() => dispatch(setDrawer({ open: false, content: null }))} />
    if (type === 'ViewSendVoDrawer') return <ViewSendVoDrawer entity={data} sync={sync} onClose={() => dispatch(setDrawer({ open: false, content: null }))} />
    if (type === 'ViewRequestFiltersDrawer') return <StockRequestModules.RequestFiltersDrawer data={entity} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if (type === 'ViewRequestNotesDrawer') return <StockRequestModules.NotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
  };
  dispatch(setDrawer({
    open: true,
    width: (width) ? width : "600px",
    content: selector(type, entity)
  }))
}

export function handleRedirectSiteStock(history) {
  return <UILIB.Link onClick={() => history.push({ pathname: '/consumablesHub/customerSiteStock' })} className={'table'}>here</UILIB.Link>;
};

export function handleSerialClicked(row, history, className) {
  return <UILIB.Link onClick={() => history.push({ pathname: `/consumablesHub/consumablesLookup/equipment/${row.serialNumber}` })} className={`table ${className}`}>{row.serialNumber}</UILIB.Link>
};

export function handleCustomerClicked(row, history, className) {
  return <UILIB.Link onClick={() => history.push({ pathname: `/consumablesHub/consumablesLookup/customer/${row.accountNumber}` })} className={`table ${className}`}>{row.customerName}</UILIB.Link>
};

export function handleSiteStockSerialClicked(row, className) {
  return <UILIB.Link onClick={() => window.open(`/consumablesHub/customerSiteStock/equipment/${row.serialNo}`, '_blank', 'noopener noreferrer')} className={`table ${className}`}>{row.serialNo}</UILIB.Link>
};

export function handleSiteStockCustomerClicked(row, className) {
  return <UILIB.Link onClick={() => window.open(`/consumablesHub/customerSiteStock/customer/${row.accountNumber}`, '_blank', 'noopener noreferrer')} className={`table ${className}`}>{row.altName}</UILIB.Link>
};

export function calculateStoreBind(stores, binds) {
  if ((!stores || !stores.length) || (!binds || !binds.length)) return -1;
  const groupSelected = stores.find(x => binds.map(x => x.storeID).includes(x.rowID));
  return (groupSelected) ? groupSelected.rowID : -1;
};

export function getDaysOfWeek(type) {
  const days = {
    1: "Sunday",
    2: "Monday",
    3: "Tuesday",
    4: "Wednesday",
    5: "Thursday",
    6: "Friday",
    7: "Saturday"
  }
  if (typeof (type) === 'undefined') return days;
  return days[type];
};

export function getDeliveryType() {
  return [
    { value: 'FEDEXCOURIER4', label: "Fedex - Next Day (FOC)" },
    { value: 'FEDEXCOURIER6', label: "Fedex - Next Day Pre 10.00 (FOC)" },
    { value: 'FEDEXCOURIER5', label: "Fedex - Next Day Pre 12.00 (FOC" },
    { value: 'FEDEXCOURIER1', label: "Fedex - Next Day (£8.95)" },
    { value: 'FEDEXCOURIER3', label: "Fedex - Next Day Pre 10.00 (£30" },
    { value: 'FEDEXCOURIER2', label: "Fedex - Next Day Pre 12.00 (£20)" },
    { value: 'DPDCOURIER', label: "DPD - Next Day (FOC)" },
    { value: 'DPDCOURIER4', label: "DPD - Next Day Pre 10.30 (FOC)" },
    { value: 'DPDCOURIER2', label: "DPD - Next Day Pre 12.00 (FOC)" },
    { value: 'DPDCOURIER3', label: "DPD - Next Day (£20)" },
    { value: 'DPDCOURIER5', label: "DPD - Next Day Pre 10.30 (£30)" },
    { value: 'DPDCOURIER1', label: "DPD - Next Day Pre 12.00 (£20)" },
    { value: 'DPDCOURIER6', label: "DPD - next Day (MPS+ Opt Out)" },
    { value: 'CDSDELCHG', label: "Capital Delivery" }]
};

export function getSelecteYield(row, selectData, userSelected) {

  if (userSelected.type !== -1) return userSelected.type;

  const defaultValue = selectData.filter(x => (x.Name && row.tonerType) ? (x.Name.toLowerCase() === row.tonerType.toLowerCase()) : x);

  const isHeighYield = (row.avgVolume > 5000);
  const foundType = (isHeighYield)
    ? selectData.find(x => String(x.Name && x.Name.toLowerCase()) === String(row.tonerType && row.tonerType.toLowerCase()) && Number(x.ExpectedYield) > 5000)
    : selectData.find(x => String(x.Name && x.Name.toLowerCase()) === String(row.tonerType && row.tonerType.toLowerCase()) && Number(x.ExpectedYield) <= 5000)

  if (foundType) {
    userSelected.type = String(foundType.value);
    return String(foundType.value);
  } else if (!foundType && defaultValue.length === 2) {
    userSelected.type = String(defaultValue[1].value);
    return String(defaultValue[1].value);
  } else {
    return -1;
  }
};

export function getPrimaryContact(selectData, currentSelect, userSelected) {

  if (!selectData) return { Id: -1, FullName: null };

  if (Number(currentSelect) !== -1) return selectData.find(x => Number(x.Id) === Number(currentSelect));

  const foundType = selectData.find(x => Number(x.IsPrimary) === 1);

  if (foundType) {
    userSelected.contact = foundType;
    return foundType;
  } else {
    return { Id: -1, FullName: null };
  }
};

export function checkManualOrder(row) {
  if (row.isManualOrder) return true;
  return false;
};

export function getEmptyFormObject() {
  return { 
    EmailAddress: '',
    FullName: '',
    Id: '',
    PhoneNumber: '',
    contactName: '',
    emailAddress: '',
    notes: '',
    telephoneNo: '',
    quickContact: -1,
    focJit: '0',
    urgentOrder: '0',
    totalDevices: 0,
    cachedData: [],
    selectedDevices: [] }
}

export function getEmptyStatsObject() {
  return { 
    grading: { primary: '', secondary: '' },
    onStop: { primary: '' },
    isActive: { primary: '' },
    devices: { primary: '' } }
}

export function generateChecked(entityData) {
  let checked = {};
  if(entityData.devices && entityData.devices.result && entityData.devices.result.length) {
    entityData.devices.result.forEach(x =>  { return checked = { ...checked, [x.Id]: false } });
    return checked;
  }
};

export function getValidRequestObject(row, updateChecked, requiredCheckbox) {
  if(
    Number(row.black) > 0 || 
    Number(row.cyan) > 0 || 
    Number(row.magenta) > 0 || 
    Number(row.yellow) > 0 || 
    Number(row.waste) > 0 || 
    Number(row.staples) > 0 || 
    Number(row.bookletstaples) > 0 || 
    Number(row.wideformatcart) > 0) {
      if(updateChecked) requiredCheckbox.current[row.Id] = true;
      return true; }
  if(updateChecked) requiredCheckbox.current[row.Id] = false;
  return false;
}

export function getCustomerGradingClass(grading) {
  if(grading === 'Bronze') return 'colour background-8 gradingBronze';
  if(grading === 'Silver') return 'colour background-8 gradingSilver';
  if(grading === 'Diamond') return 'colour background-8 gradingDiamond';
  if(grading === 'Gold') return 'colour background-8 gradingGold';
  if(grading === 'Platinum') return 'colour background-8 gradingPlatinum';
  return 'colour background-8 lightGrey';
}

export function getTableHeaderColour(colour) {
  if(colour === 'black') {
    return <div className='flex-container row nowrap width-100'>
      <div className="flex-item align-center circle table background-color-black min-width-10 mar-r5"></div>
      <div className="flex-item align-center flex-grow-1">Black Toner</div>
    </div>; }
  if(colour === 'cyan') {
    return <div className='flex-container row nowrap width-100'>
      <div className="flex-item align-center circle table background-color-cyan min-width-10 mar-r5"></div>
      <div className="flex-item align-center flex-grow-1">Cyan Toner</div>
    </div>; }
  if(colour === 'magenta') {
    return <div className='flex-container row nowrap width-100'>
      <div className="flex-item align-center circle table background-color-magenta min-width-10 mar-r5"></div>
      <div className="flex-item align-center flex-grow-1">Magenta Toner</div>
    </div>; }
  if(colour === 'yellow') {
    return <div className='flex-container row nowrap width-100'>
      <div className="flex-item align-center circle table background-color-yellow min-width-10 mar-r5"></div>
      <div className="flex-item align-center flex-grow-1">Yellow Toner</div>
    </div>; }
  return <div></div>;
}

export function getManualOrderDrawerNotes(row, history) {
  return {
    createdAt: moment(row.createdAt).utc(true).format("YYYY-MM-DD HH:mm:ss"),
    createdByTxt: "MANUAL ORDER",
    notes: <ManualOrderContainer data={row} history={history} showChip={false} clickable={false} />
  }
}

