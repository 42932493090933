import { Fragment } from 'react';
import UILIB from 'components';
import moment from "moment";


export default function ReportDrawer({ entity }) 
{  

  const reportLog = (entity && entity.report && entity.report.length) 
    ? entity.report.replace(/<br \/>/g, '\n').trim()
    : '';

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className={`flex-item start width-100`}>
        {entity.emailAddress && <h2>Report Log for {entity.emailAddress}</h2>}
        {!entity.emailAddress && <h2>Report Log</h2>}
      </div>
      <div className={`flex-item end width-100`}>
        <UILIB.NoteContainer 
          type={'Report Log Event'} 
          createdBy={moment(entity.created).utc(true).format('YYYY-MM-DD HH:mm')} 
          updatedAt={moment(entity.updated).utc(true).format('YYYY-MM-DD HH:mm')} 
          message={reportLog} />
      </div>
    </div>
  </Fragment>
}
