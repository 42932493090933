import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from "moment";
import { downloadFileHandler } from 'classes/download';
import { createDateInput, createSelectInput, createCheckBox, createMultiSelectInput } from 'classes/controls/formControls';


export default function MasterDealSummarySheetDrawer ({ data }) {

  const [filtersLocal, setFiltersLocal] = useState(data.filters.masterDeal);
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState({ error: false, message: '' });
  const [saving, setSaving] = useState(false);
  const [workflowEvents, setWorkflowEvents] = useState([]);
  const exceptions = [400,404,204];

  const noContent = 'The options selected returned no results, please try again';
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while fetching this data, please reload or contact support';

  
  const updateDates = (newDate, context) => {
    filtersLocal[context][newDate.target.name] = newDate.target.value;
    setFiltersLocal({ ...filtersLocal });
  }

  const handleOptionsChanged = (event, name) => {
    if (filtersLocal[name] === event.target.name) {
      setFiltersLocal({ ...filtersLocal, [name]: '' });
    } else {
      setFiltersLocal({ ...filtersLocal, [name]: event.target.value });
    }
  };

  const handleTickedChanged = (event) => {
    filtersLocal[event.target.name].disabled = !filtersLocal[event.target.name].disabled;
    setFiltersLocal({ ...filtersLocal });
  }

  const handleGenerateCSV = async () => {

    setSaving(true);
    setBannerError(defaultBanner);

    try { 
      const response = await Axios.post(`/entities/workflow/deals/actionGenerateDeclinedSheet`, filtersLocal);
      if(exceptions.includes(response.status)) {
        if(response.status === 204) setBannerError({ error: true, message: noContent });
        if(response.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(response.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setBannerError(defaultBanner);
        downloadFileHandler(`DealDeclineReport_${moment().utc(true).format('YYYYMMDD_HHMMss')}`, 'csv', response.data.result);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }

    setSaving(false);
  }

  useEffect(() => {
    const actionAsync = async () => {
      const workflowEvents = await Axios.get(`/entities/serviceAdmin_salesDeals2EventTypes`);
      const eventsMapped = workflowEvents.data.result.map(x => { return { label: x.name, value: x.id } });
      setWorkflowEvents(eventsMapped);
    }
    actionAsync();
  }, []);


  return <Fragment>         
    <div className="flex-container row start width-100 mar-3">
      <div className="flex-item row width-100">
        {createCheckBox('', 'datesEvent', saving, filtersLocal.datesEvent.disabled,  (ev) => handleTickedChanged(ev), 'flex-grow-1 width-5')}
        {createDateInput('Event Date Start', 'eventDateStart', (saving || !filtersLocal.datesEvent.disabled), filtersLocal.datesEvent.eventDateStart, (ev) => updateDates(ev, 'datesEvent'), null, 'flex-grow-1 width-45')}
        {createDateInput('Event Date End', 'eventDateEnd', (saving || !filtersLocal.datesEvent.disabled), filtersLocal.datesEvent.eventDateEnd, (ev) => updateDates(ev, 'datesEvent'), null, 'flex-grow-1 width-45')}
      </div>
      {createMultiSelectInput('Workfow Events', 'workflowIds', (saving || !workflowEvents.length), filtersLocal.workflowIds, workflowEvents, 'All Events', (ev) => handleOptionsChanged(ev, 'workflowIds'), null, 'flex-grow-1 width-100')}
      {createMultiSelectInput('Region', 'regionIds', saving, filtersLocal.regionIds, data.data.regionData, 'All Regions', (ev) => handleOptionsChanged(ev, 'regionIds'), null, 'flex-grow-1 width-100')}
      {createSelectInput('Sales Person', 'staff', saving, filtersLocal.staff, data.data.staffData, (ev) => handleOptionsChanged(ev, 'staff'), null, 'flex-grow-1 width-100')}
    </div>
    <div className="flex-container row start width-100 mar-3 mar-t20">
      <UILIB.Button name="Generate Report" value="Generate Report" loading={saving} disabled={saving} errored={bannerError} onClick={async () => await handleGenerateCSV()} />
      {bannerError.error && <div className="flex-container salesHub submitDeal-button center">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>   
}
