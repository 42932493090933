import { useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setPagePreferences } from "store/actions";
import Axios from 'classes/axios';
import ManualOrderContainer from './manualOrderContainer';
import UILIB from 'components';
import moment from 'moment';
import { createMultiSelectInput } from 'classes/controls/formControls';
import { consumableChip, statusChip, tableContainer, setFilterVars, actionOpenDrawer, handleSerialClicked, handleCustomerClicked, getBGColour, getYesNo, checkManualOrder } from '../functions';


export default function TonerLookupTable({ context, topStats, setTopStats, regions }) {
    const headerData = [
      { label: '', value: 'tonerID', type: 'delete', filtering: false, minWidth: 30, maxWidth: 30 },
      { label: 'Customer Name', value: 'customerName', type: 'string', filtering: true, minWidth: 370, maxWidth: 370 },
      { label: 'Model', value: 'machineName', type: 'string', filtering: true, minWidth: 250, maxWidth: 250 },
      { label: 'Serial #', value: 'serialNumber', type: 'string', filtering: true, minWidth: 130, maxWidth: 130 },
      { label: 'Contract', value: 'sa_seDesc', type: 'string', filtering: true, minWidth: 190, maxWidth: 190 },
      { label: 'Order Date', value: 'tonerDate', type: 'datetime', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Requested', value: 'tonerTypeCast', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
      { label: 'Location', value: 'locationName', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
      { label: 'Region', value: 'name', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
      { label: 'Source', value: 'source', type: 'string', filtering: true , minWidth: 130, maxWidth: 130 },
      { label: 'Status', value: 'status', type: 'string', filtering: true, minWidth: 120 },
      { label: 'Notes', value: 'notes', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
      { label: 'Alerts', value: 'alerts', type: 'string', filtering: true, minWidth: 60, maxWidth: 60, filterArray: getYesNo() }];

    const dispatch = useDispatch();
    const history = useHistory();
    const entityData = useRef({});
    const pageContext = context.context;
    const preferences = useSelector((state) => state.pagePreferences);
    const defaultDialogue = { tonerIds: [], count: 0, show: false };
    const defaultBanner = { error: false, message: '' };
    const [showDialogue, setShowDialoge] = useState(defaultDialogue);
    const [bannerError, setBannerError] = useState(defaultBanner);
    const tablePageDefaults = { deleteEnabled: true, caching: true };
    const [filter, setFilter] = useState({ button: '' });
    const [localRender, setLocalRender] = useState(false);
    const [selected, setSelected] = useState(preferences.consumablesHub.regions);
    const [deleting, setDeleting] = useState(false);
    const exceptions = [400,404,500];

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';


    const tableDeleteToner = async (event) => {
      setShowDialoge({ tonerIds: event, count: event.length, show: true });
    };

    const actionCloseDelete = () => {
      setShowDialoge({ ...showDialogue, show: false });
      setBannerError(defaultBanner);
    };

    const actionDeleteToner = async (tonerIds) => {
      setDeleting(true);
      try { 
        const deleteObjects = tonerIds.map(x => { return { tonerID: x } });
        const result = await Axios.delete('/entities/tonerOrders/', { data: { entity: deleteObjects } }); 
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          entityData.current.result = entityData.current.result.filter(x => !tonerIds.map(m => Number(m)).includes(Number(x.tonerID)));
          setShowDialoge(defaultDialogue);
          setLocalRender(!localRender);
          setDeleting(false);
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
      setDeleting(false);
    };

    const handleButtonsChanged = (buttonName, set) => {
      const newTableFilter = { ...filter };
      if (newTableFilter.button === buttonName) {
          newTableFilter.button = '';
      } else {
          newTableFilter.button = buttonName;
      }
      set(newTableFilter);
    };

    const handleRegionChanged = (selected) => {
      dispatch(setPagePreferences({ ...preferences, consumablesHub: { ...preferences.consumablesHub, regions: [...selected] } }));
      setSelected([...selected]);
    };

    async function constructTable(tonderData) {

        const tableData = tonderData.map(row => {
          
            const sourceData = Array.from(new Set([row.fromAlertType, row.raisedBy])).join(' - ');

            const b_hasNotes = Boolean(row.notes && row.notes.length);
            const b_hasAlerts = Boolean((row.storeNote && row.storeNote.length) || (row.itemnote && row.itemnote.length) || (row.stockHoldingNote && row.stockHoldingNote.length));

            const notesColour = (b_hasNotes) ? 'colour background-6 red' : 'colour lightGrey';
            const alertsColour = (b_hasAlerts) ? 'colour background-6 red' : 'colour lightGrey';

            const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });
            const fn_alerts = actionOpenDrawer.bind(null, 'ViewAlertsDrawer', row, dispatch, b_hasAlerts);

            const tonerDate = moment(row.tonerDate).utc(true).format("DD/MM/YYYY HH:mm:ss");

            const bgColour = getBGColour(row);

            const customerContainer = (checkManualOrder(row))
              ? <ManualOrderContainer data={row} history={history} />
              : handleCustomerClicked(row, history, null)

            return {
              tonerID: { value: row.tonerID, raw: row.tonerID, class: bgColour },
              customerName: { value: customerContainer, raw: row.customerName, class: bgColour },
              machineName: { value: row.machineName, raw: row.machineName, class: bgColour },
              serialNumber: { value: handleSerialClicked(row, history, null), raw: row.serialNumber, class: bgColour },
              locationName: { value: row.locationName, raw: row.locationName, class: bgColour },
              sa_seDesc: { value: row.sa_seDesc, raw: row.sa_seDesc, class: bgColour },
              tonerDate: { value: tonerDate, raw: row.tonerDate, class: bgColour },
              tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast, class: bgColour },
              name: { value: row.name, raw: row.name, class: bgColour },
              source: { value: sourceData, raw: sourceData, class: bgColour },
              status: { value: statusChip(row.status, row, row.status), raw: row.status, class: bgColour },
              notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes },
              alerts: { value: tableContainer(fn_alerts, 'icon-bullhorn', (b_hasAlerts) ? 'View Alerts' : 'Alerts Unavailable', alertsColour, true), raw: b_hasAlerts }
            }
        })
        return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken, source) => {

      const queryLocal = (query !== null) ? query : '';
      const expandArray = (source === 'primary') ? ['storeNote', 'itemNote', 'notes', 'stockHoldingNote'] : [];
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const filterButtons = setFilterVars(pageContext, filter);
      const regionSelected = (selected.length) ? ` ServiceRegionId in ${selected.join(',')} ` : '';
      const select = ['tonerID', 'customerName', 'accountNumber', 'custName', 'custEmail', 'telNo', 'customerID', 'machineName', 'serialNumber', 'sa_inkey', 'sa_cukey', 'sa_seDesc', 'tonerDate', 'tonerType', 'tonerTypeCast', 'locationName', 'name', 'source', 'status', 'isManualOrder', 'fromAlertType', 'raisedBy', 'urgentOrder', 'over3Days'];

      if(context.context === 'chargable') expandArray.push('consumable');
      
      entityData.current = await Axios.get(`/entities/tonerOrders/getSummary/${pageContext}/?&$filter=${[filterButtons, regionSelected].filter(x => x).join(' and ')}${queryLocal}&$select=${select.join(' and ')}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
      entityData.current.result.sort((a, b) => { return b.urgentOrder - a.urgentOrder });

      if(!entityData.current || !entityData.current.result.length) return false;

      setTopStats({ ...topStats, ...entityData.current.topStats });

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }

    
    return <Fragment>
        {showDialogue.show && <UILIB.MessageBox 
          header={'Delete Records'} 
          loading={deleting}
          text={`Click OK to DELETE the selected ${(showDialogue.count) > 1 ? 'Records' : 'Record'}`} 
          onConfirm={async () => await actionDeleteToner(showDialogue.tonerIds)} 
          onCancel={() => actionCloseDelete()} 
          errored={bannerError} />}
        <UILIB.Paper className='width-100'>
            <div className='flex-container row mar-b10'>
              <div className='flex-item flex-grow-1 start wrap'>
                <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
                <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Over 3 Days' ? 'primary' : 'secondary')} name='Over 3 Days' value='Over 3 Days' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
                <UILIB.Button className={'mar-l5 small ' + (!filter.button || filter.button === 'Urgent' ? 'primary' : 'secondary')} name='Urgent' value='Urgent' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
              </div>
              <div className='flex-item end'>
                {createMultiSelectInput('', 'selectRegion', false, selected, regions, 'All Regions', (ev) => handleRegionChanged(ev.target.value), null, null, null, null, null)}
              </div>
            </div>
            <UILIB.TableNew
              name={`Consumables${pageContext}`}
              className='small'
              overflowX='auto'
              overflowY='hidden'    
              header={headerData}
              deleteQuery={async (ev) => await tableDeleteToner(ev)}
              localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
              localRender={[localRender]}
              remoteQuery={getTableData}
              remoteRender={[selected, filter, context.context]}
              defaultSettings={tablePageDefaults} />
        </UILIB.Paper>
    </Fragment>
}