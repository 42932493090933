import { useState, useRef, memo, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';
import DealLinkTableCell from '../../shared/dealLink';
import BookInstallModal from "../modules/bookInstallModal";
import { downloadFileHandler } from 'classes/download';
import { getInstallStatusChip, getYesNo } from '../functions';


const Installs = memo(({ tab, permissionsObject, localPermissions, permissions, route }) => {

  const headerData = [
    { label: '', value: 'id', type: 'delete', filtering: false, minWidth: 30, maxWidth: 30 },
    { label: "#", value: "finalDealNo", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Customer", value: "companyName", type: 'string', filtering: true, minWidth: 250, maxWidth: 250 },
    { label: "Delivery Address", value: "delAddress", type: 'string', filtering: true, minWidth: 250, maxWidth: 250 },
    { label: "Sales Person", value: "salesPersonName", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Move Date", value: "moveDateCast", type: 'date', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Created Date", value: "createdAtCast", type: 'date', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Download Install PDF", value: "downloadInstallPDF", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Download Collection PDF", value: "downloadCollectPDF", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Status", value: "status", type: 'string', filtering: true, minWidth: 100, maxWidth: 100, filterArray: getYesNo() },
    { label: "View / Edit", value: "editView", type: 'string', filtering: false, minWidth: 80, maxWidth: 80 }];
 
  const history = useHistory();
  const entityData = useRef({});
  const downloadInstall = useRef({});
  const downloadCollection = useRef({});
  const tablePageDefaults = { deleteEnabled: true, paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
  const defaultDialogue = { tonerIds: [], count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [showDialogue, setShowDialoge] = useState(defaultDialogue);
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [deviceModalData, setDeviceModalData] = useState({ show: false, entity: null });
  const [localRender, setLocalRender] = useState(Math.random());
  const [deleting, setDeleting] = useState(false);
  const exceptions = [400,404,500];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const tableDeleteInstall = (event) => {
    setShowDialoge({ installIds: event, count: event.length, show: true });
  };

  const actionCloseDelete = () => {
    setShowDialoge({ ...showDialogue, show: false });
    setBannerError(defaultBanner);
  };

  const actionDeleteInstall = async (installIds) => {
    setDeleting(true);
    try { 
      const deleteObjects = entityData.current.result.filter(x => installIds.map(m => Number(m)).includes(Number(x.id)));
      const result = await Axios.delete('/entities/workflow/installs/actionDeleteFormData/', { data: { entity: deleteObjects } }); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        entityData.current.result = entityData.current.result.filter(x => !installIds.map(m => Number(m)).includes(Number(x.id)));
        setShowDialoge(defaultDialogue);
        setLocalRender(Math.random());
        setDeleting(false);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setDeleting(false);
  };

  const handleDownloadPDF = async (row, field, context, ref) => {
    try { 
      ref.current = { ...ref.current, [row.id]: true };
      setLocalRender(Math.random());
      const result = await Axios.get(`/entities/workflow/installs/actionGeneratePDF/${row.dealId}/${row.uuid}/${context}`);
      if(!exceptions.includes(result.status)) downloadFileHandler(row[field], 'pdf', result.data.result.content);
      ref.current = { ...ref.current, [row.id]: false };
      setLocalRender(Math.random());
    } catch (err) {
      ref.current = { ...ref.current, [row.id]: false };
      setLocalRender(Math.random());
    }
  };


  async function constructTable(dealData) {

    const permissionDealLink = permissionsObject('orderLink', localPermissions.current.permissions, permissions, route);

    const tableData = dealData.map(row => {

      const payload = {
        pathname: `/logisticsHub/deviceInstall/${row.uuid}`, 
        state: { dealData: null, existingForm: true, dealId: row.dealId, uuid: row.uuid, tab: tab } };

      const editView = <UILIB.Button className="table green text-small width-100" value="Edit" onClick={() => history.push(payload)} />;

      const fn_handleinstallPDF = handleDownloadPDF.bind(null, row, 'fnInstall', 0, downloadInstall);
      const fn_handlecollectionPDF = handleDownloadPDF.bind(null, row, 'fnCollection', 1, downloadCollection);

      const installDownload = <UILIB.Button 
        className="table text-small colour background orange width-100"
        disabled={!row.fnInstall}
        name={`installDownload-${row.id}`}
        value="Download PDF"
        loading={downloadInstall.current[row.id]} 
        onClick={fn_handleinstallPDF} />

      const collectionDownload = <UILIB.Button 
        className="table text-small colour background orange width-100"
        disabled={!row.fnCollection}
        name={`collectionDownload-${row.id}`}
        value="Download PDF"
        loading={downloadCollection.current[row.id]} 
        onClick={fn_handlecollectionPDF} />


      return {
        id: { value: row.id, raw: row.id },
        finalDealNo: DealLinkTableCell({ ...row, dealID: row.dealId }, history, route, permissionDealLink.entityEnable, null, true, 'class'),
        companyName: { value: row.companyName, raw: row.companyName },
        delAddress: { value: row.delAddress, raw: row.delAddress },
        salesPersonName: { value: row.salesPersonName, raw: row.salesPersonName },
        moveDateCast: { value: moment(row.moveDate).utc(true).format("DD/MM/YYYY HH:mm:ss"), raw: row.moveDate },
        createdAtCast: { value: moment(row.createdAt).utc(true).format("DD/MM/YYYY HH:mm:ss"), raw: row.createdAt },
        downloadInstallPDF: { value: installDownload, raw: 0 },
        downloadCollectPDF: { value: collectionDownload, raw: 0 },
        status: { value: getInstallStatusChip(row), raw: row.status },
        editView: { value: editView, raw: 0 }
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    entityData.current = await Axios.get(`/entities/installs/getSummary/?&$filter=${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    let installDownloadMapped = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x =>  { return installDownloadMapped = { ...installDownloadMapped, [x.id]: false } });
      downloadInstall.current = installDownloadMapped;
    }

    let collectionDownloadMapped = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x =>  { return collectionDownloadMapped = { ...collectionDownloadMapped, [x.id]: false } });
      downloadCollection.current = collectionDownloadMapped;
    }

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    {showDialogue.show && <UILIB.MessageBox 
      header={'Delete Records'} 
      loading={deleting}
      text={`Click OK to DELETE the selected ${(showDialogue.count) > 1 ? 'Records' : 'Record'}`} 
      onConfirm={async () => await actionDeleteInstall(showDialogue.installIds)} 
      onCancel={() => actionCloseDelete()} 
      errored={bannerError} />}
    {deviceModalData.show && <BookInstallModal 
      history={history} 
      pdiModalData={deviceModalData} 
      setPdiModalData={setDeviceModalData} />}
    <UILIB.Paper className='width-100'>
      <UILIB.TableNew
        name={`DealsLogistics`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        deleteQuery={(ev) => tableDeleteInstall(ev)}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender]}
        remoteQuery={getTableData}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
})
export default Installs