import { useRef, memo, Fragment, useState } from 'react';
import { formatDateTime } from "classes/format";
import { useDispatch } from "react-redux";
import { actionOpenKitFinderDrawer, getTableHeaderWithInfoIcon } from '../functions';
import TableModules from "components/Table/Modules";
import Axios from 'classes/axios';
import UILIB from 'components';
import moment from "moment";
import { downloadFileHandler } from 'classes/download';


const CustomerInventoryTable = memo(({ entityLookup }) => {

  const [expandAll, setExpandAll] = useState(false);
  
  const headerData = [
    { label: <TableModules.ExpandContainer getState={expandAll} setState={setExpandAll} />, value: 'meterData', filtering: false, minWidth: 20, maxWidth: 20 },
    { label: "Customer Name", value: "customerName", type: 'string', filtering: false, minWidth: 200, maxWidth: 200, clickable: true },  
    { label: "Account Number", value: "accountNumber", type: 'string', filtering: false, minWidth: 100, maxWidth: 100, clickable: true },  
    { label: 'Serial Number', value: 'SerialNumber', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Device Name', value: 'Name', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Location', value: 'Location', type: 'string', filtering: true, minWidth: 160, maxWidth: 160, clickable: true },
    { label: 'Asset Number', value: 'AssetNumber', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Install Date', value: 'InstallDate', type: 'date', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Payment Method', value: 'PaymentMethodName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: 'Payment Period', value: 'PaymentPeriodName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120, clickable: true },
    { label: '', value: 'KitFinder', type: 'string', filtering: false, minWidth: 50, maxWidth: 50 }];

  const headerDataNested = [
    { label: "Meter", value: "name", type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },  
    { label: 'Last Invoice Reading', value: 'reading', type: 'number', filtering: false, minWidth: 110, maxWidth: 110 },
    { label: 'Meter Reading Date', value: 'readingDate', type: 'date', filtering: false, minWidth: 110, maxWidth: 110 },
    { label: 'CPC', value: 'cpc', type: 'number', filtering: false, minWidth: 110, maxWidth: 110 },
    { label: getTableHeaderWithInfoIcon('Monthly avarage over the last 12 months', 'Avg Vol'), value: 'AvgPerMonth', type: 'number', filtering: false, minWidth: 110, maxWidth: 110 },
    { label: 'Contract Name', value: 'contractName', type: 'string', filtering: false, minWidth: 400, maxWidth: 400 }];

  const tablePageDefaults = { expandData: { key: 'meterData', active: true }, nestedData: true, paging: { pages: [25,50,75,100,200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'SerialNumber', orderDir: 'ASC' } };
  const [bannerError, setBannerError] = useState({ error: false, message: '' });
  const defaultBanner = { error: false, message: '' };
  const entityData = useRef({});
  const dispatch = useDispatch();
  const exceptions = [400,404,204];

  const noContent = 'The options selected returned no results, please try again';
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';

  async function constructTable(inventoryData) {
    const tableData = inventoryData.map(row => {
      const kitFinder = <UILIB.TableContainer data={
                          <UILIB.Button 
                            value="Kit Finder" 
                            className={`table ${row.kit && row.kit.length < 1 ? 'grey' : 'green'}`} 
                            onClick={async () => await actionOpenKitFinderDrawer('ViewKitFinderDrawer', row.kit, dispatch, true)}
                          />
                          } 
                          className="width-100 height-100" 
                          disabled={row.kit && row.kit.length < 1} 
                        />

      const subClass = 'colour background-4 limeGreen';

      const meterData = (inventoryData.filter(x => x.Id === row.Id)[0]?.meterdata?.result || []).map(x => {
        return {
          name: { value: x.Name, raw: x.Name, class: subClass },
          reading: { value: x.Reading, raw: x.Reading, class: subClass },
          readingDate: { value: formatDateTime(x.ReadingDate, "DD/MM/YYYY"), raw: x.ReadingDate, class: subClass },
          cpc: { value: x.CPC, raw: x.CPC, class: subClass },
          AvgPerMonth: { value: x.AvgPerMonth, raw: x.AvgPerMonth, class: subClass },
          contractName: { value: x.ContractName, raw: x.ContractName, class: subClass }
        }
      })

      return {
        customerName: { value: row.CustomerName, raw: row.CustomerName },
        accountNumber: { value: row.AccountNumber, raw: row.AccountNumber },
        SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
        Name: { value: row.Description, raw: row.Description },
        Location: { value: row.Location, raw: row.Location },
        AssetNumber: { value: row.AssetNumber, raw: row.AssetNumber },
        InstallDate: { value: formatDateTime(row.InstallDate, "DD/MM/YYYY"), raw: row.InstallDate },        
        PaymentMethodName: { value: row.PaymentMethodName, raw: row.PaymentMethodName },
        PaymentPeriodName: { value: row.PaymentPeriodName, raw: row.PaymentPeriodName },        
        KitFinder: { value: kitFinder },
        meterData: { 
          value: 0,
          raw: {
            isOpen: (meterData.length) ? expandAll : false,
            uniqueKey: row.Id,
            nested: true, 
            expand: (meterData.length) ? true : false, 
            entityData: meterData, 
            headerData: headerDataNested, 
            tableSettings: { headerEnabled: true }
          }
        }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
    const expandArray = ['kit','meterData'];

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const categoryIds = [15, 69, 66];
    const categoryFilter = `and CategoryId nin ${categoryIds.join(',')}`

    if(entityLookup.context === 'customer') {
      entityData.current.devices = await Axios.get(`/entities/equipments/getSummaryCustomerInventoryReport/?&$filter=CustomerId in ${entityLookup.customerIds.length ? entityLookup.customerIds : ''} ${categoryFilter} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(entityLookup.context === 'equipment') {
      entityData.current.devices = await Axios.get(`/entities/equipments/getSummaryCustomerInventoryReport/?&$filter=Id in ${entityLookup.equipmentIds.length ? entityLookup.equipmentIds : ''} ${categoryFilter} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(entityLookup.context === 'postCode') {
      entityData.current.devices = await Axios.get(`/entities/equipments/getSummaryCustomerInventoryReport/?&$filter=PostCode in ${entityLookup.postCodes.length ? entityLookup.postCodes : ''} ${categoryFilter} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(!entityData.current.devices || !entityData.current.devices.result || !entityData.current.devices.result.length) return false;

    const devices = entityData.current.devices.result;
    
    return { tableData: await constructTable(devices), raw: devices, nonePaged: entityData.current.devices.nonePaged };
  }

  const getExportData = async () => {
    const categoryIds = [15, 69, 66];
    const categoryFilter = `and CategoryId nin ${categoryIds.join(',')}`
    let response;

    if(entityLookup.context === 'customer' && entityLookup.customerIds) {
      response = await Axios.get(`/entities/workflow/deals/generateCustomerInventoryReport?&$filter=CustomerId in ${entityLookup.customerIds.length ? entityLookup.customerIds : ''} ${categoryFilter}`);
    }

    if(entityLookup.context === 'equipment') {
      response = await Axios.get(`/entities/workflow/deals/generateCustomerInventoryReport?&$filter=Id in ${entityLookup.equipmentIds.length ? entityLookup.equipmentIds : ''} ${categoryFilter}`);
    }

    if(entityLookup.context === 'postCode') {
      response = await Axios.get(`/entities/workflow/deals/generateCustomerInventoryReport?&$filter=PostCode in ${entityLookup.postCodes.length ? entityLookup.postCodes : ''} ${categoryFilter}`);
    }

    if(exceptions.includes(response.status)) {
      if(response.status === 204) setBannerError({ error: true, message: noContent });
      if(response.status === 400) setBannerError({ error: true, message: invalidConfig });
      if(response.status === 404) setBannerError({ error: true, message: notFoundError });
    } else {
      setBannerError(defaultBanner);
      return response.data.result;
    }
  }

  const exportData = async (data) => {
    downloadFileHandler(`CustomerInventoryReport_${moment().utc(true).format('YYYYMMDD_HHMMss')}`, 'csv', data);
  }

  return <Fragment>
    <div className="pad-5 width-100 mar-b20 mar-t20">
      <b className="flex-item row  width-100 start text-12 pad-b2 mar-b2">Devices</b>
      <div className="width-100">
        <UILIB.TableNew
          name="CustomerInventory"
          className='small'
          overflowX='auto'
          overflowY='hidden'    
          header={headerData}
          localQuery={() => constructTable((entityData.current && entityData.current.devices) ? entityData.current.devices.result : [])}
          remoteQuery={getTableData}
          exportQuery={getExportData}
          exportResponse={exportData}
          localRender={[expandAll]}
          remoteRender={[entityLookup]}
          defaultSettings={tablePageDefaults} />
      </div>
    </div>
    {bannerError.error && <div className="flex-container end width-100">
        <div className="errored message">{bannerError.message}</div>
    </div>}
  </Fragment>
})
export default CustomerInventoryTable